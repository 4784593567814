import TSVGPropTypes from "@customTypes/SVGPropTypes"

function PlanIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<polygon
				points="14.81 9.35 92.35 9.35 92.35 95 15.33 95 7.65 87.14 7.65 53.91 14.81 46.57 14.81 9.35"
				fill={bgColor}
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="50.9"
				y1="9.35"
				x2="50.9"
				y2="22.82"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="62.78"
				y1="5"
				x2="62.78"
				y2="15.48"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="77.35"
				y1="5"
				x2="77.35"
				y2="15.48"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="14.95"
				y1="46.83"
				x2="62.78"
				y2="46.83"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="76.11"
				y1="46.83"
				x2="92.25"
				y2="46.83"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="50.9"
				y1="35.4"
				x2="50.9"
				y2="46.83"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="41.45"
				y1="61.61"
				x2="41.45"
				y2="95"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<polyline
				points="92.25 61.61 66.81 61.61 66.81 95"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
			<line
				x1="92.25"
				y1="77.65"
				x2="66.81"
				y2="77.65"
				fill="none"
				stroke={strokeColor}
        strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default PlanIcon
