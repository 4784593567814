import { useGLTF } from "@lib/useGLTF"
import { useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { useLocation } from "react-router-dom"
import { gsap, Sine } from "gsap"
import { useThree } from "@react-three/fiber"
import globalStyles from "@data/globalStyles"

import AmenitiesBlocks from "@canvas_components/AmenitiesBlocks"
import UnitsModels from "@canvas_components/UnitsModels/UnitsModels"

import { useStore } from "@state/store"
import { useTexture } from "@react-three/drei"

const Building = () => {
	const [buildingInCamera, setBuildingInCamera] = useState(false)
	const amenityModelVisible = useStore(s => s.amenityModelVisible)
	const { nodes } = useGLTF("models/building/building.glb")
	const { nodes: garden } = useGLTF("models/building/garden.glb")
	const { nodes: compass } = useGLTF("models/building/compass.glb")
	const { nodes: roads } = useGLTF("models/building/roads.glb")

	const gardenTexture = useTexture("/textures/Garden.jpg")
	gardenTexture.flipY = false

	const { pathname } = useLocation()
	const matRef = useRef<THREE.MeshMatcapMaterial>()
	const meshRef = useRef<THREE.Mesh>()
	const groupRef = useRef<THREE.Group>()
	const duration = 0.8
	const delay = 0.5
	const opacity = 0.03
	const { camera, scene } = useThree()

	useEffect(() => {
		const condition = pathname === "/amenities"
		matRef.current &&
			gsap.to(matRef.current, {
				duration,
				opacity: condition ? opacity : 1,
				ease: Sine.easeInOut
			})
	}, [pathname])

	function moveGroupTo(to: "scene" | "camera") {
		if (to === "scene") {
			scene.remove(camera)
			camera.remove(groupRef.current)
			scene.add(groupRef.current)
			groupRef.current.position.set(0, 0, 0)
			setBuildingInCamera(false)
		}
		if (to === "camera") {
			scene.remove(groupRef.current)
			camera.add(groupRef.current)
			const width = innerWidth
			const height = innerHeight
			const distance = -3
			//@ts-ignore
			const fov = (camera.fov * Math.PI) / 180
			const h = 2 * Math.tan(fov / 2) * distance
			const w = h * (width / height)
			groupRef.current.position.set(w * 0.5 + 0.85, h * 0.12, distance)
			scene.add(camera)
			setBuildingInCamera(true)
		}
		gsap.to(matRef.current, {
			duration,
			opacity: opacity,
			delay,
			ease: Sine.easeInOut
		})
	}

	useEffect(() => {
		if (amenityModelVisible && groupRef.current && !buildingInCamera) {
			matRef.current &&
				gsap.to(matRef.current, {
					duration,
					opacity: 0,
					ease: Sine.easeInOut,
					onComplete: () => {
						moveGroupTo("camera")
					}
				})
		}
		if (!amenityModelVisible && groupRef.current && buildingInCamera) {
			matRef.current &&
				gsap.to(matRef.current, {
					duration,
					opacity: 0,
					ease: Sine.easeInOut,
					onComplete: () => {
						moveGroupTo("scene")
					}
				})
		}
	}, [amenityModelVisible])

	return (
		<group ref={groupRef}>
			<mesh geometry={nodes["mesh_0"].geometry} ref={meshRef}>
				<meshMatcapMaterial
					ref={matRef}
					color="#24408E"
					flatShading
					side={THREE.DoubleSide}
					transparent
				/>
			</mesh>
			<mesh geometry={garden["mesh_0"].geometry} ref={meshRef}>
				<meshBasicMaterial map={gardenTexture} transparent />
			</mesh>
			{pathname === "/residences" && (
				<>
					<mesh geometry={compass["mesh_0"].geometry} ref={meshRef}>
						<meshMatcapMaterial
							color={globalStyles.colors.secondary}
							flatShading
							side={THREE.DoubleSide}
							transparent
						/>
					</mesh>

					<mesh geometry={roads["mesh_0"].geometry} ref={meshRef}>
						<meshMatcapMaterial
							color={globalStyles.colors.white}
							flatShading
							side={THREE.DoubleSide}
							transparent
						/>
					</mesh>
				</>
			)}
			<AmenitiesBlocks />
			<UnitsModels />
		</group>
	)
}

export default Building
