const backUpData = [
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041654",
		FloorplanName: "A01",
		ApartmentId: "38170661",
		ApartmentName: "0331",
		Beds: "1",
		Baths: "1.00",
		SQFT: "568",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041654",
		FloorplanName: "A01",
		ApartmentId: "38170662",
		ApartmentName: "0431",
		Beds: "1",
		Baths: "1.00",
		SQFT: "568",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041654",
		FloorplanName: "A01",
		ApartmentId: "38170663",
		ApartmentName: "0531",
		Beds: "1",
		Baths: "1.00",
		SQFT: "568",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041654",
		FloorplanName: "A01",
		ApartmentId: "38170664",
		ApartmentName: "0631",
		Beds: "1",
		Baths: "1.00",
		SQFT: "568",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041654",
		FloorplanName: "A01",
		ApartmentId: "38170665",
		ApartmentName: "0726",
		Beds: "1",
		Baths: "1.00",
		SQFT: "568",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041671",
		FloorplanName: "A010",
		ApartmentId: "38170742",
		ApartmentName: "0436",
		Beds: "1",
		Baths: "1.00",
		SQFT: "610",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041671",
		FloorplanName: "A010",
		ApartmentId: "38170743",
		ApartmentName: "0536",
		Beds: "1",
		Baths: "1.00",
		SQFT: "610",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041671",
		FloorplanName: "A010",
		ApartmentId: "38170744",
		ApartmentName: "0636",
		Beds: "1",
		Baths: "1.00",
		SQFT: "610",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041672",
		FloorplanName: "A011",
		ApartmentId: "38170745",
		ApartmentName: "0340",
		Beds: "1",
		Baths: "1.00",
		SQFT: "598",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041672",
		FloorplanName: "A011",
		ApartmentId: "38170746",
		ApartmentName: "0442",
		Beds: "1",
		Baths: "1.00",
		SQFT: "598",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041672",
		FloorplanName: "A011",
		ApartmentId: "38170747",
		ApartmentName: "0542",
		Beds: "1",
		Baths: "1.00",
		SQFT: "598",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041672",
		FloorplanName: "A011",
		ApartmentId: "38170748",
		ApartmentName: "0642",
		Beds: "1",
		Baths: "1.00",
		SQFT: "598",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041673",
		FloorplanName: "A012",
		ApartmentId: "38170749",
		ApartmentName: "0335",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041673",
		FloorplanName: "A012",
		ApartmentId: "38170750",
		ApartmentName: "0435",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041673",
		FloorplanName: "A012",
		ApartmentId: "38170751",
		ApartmentName: "0535",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041673",
		FloorplanName: "A012",
		ApartmentId: "38170752",
		ApartmentName: "0635",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041673",
		FloorplanName: "A012",
		ApartmentId: "38170753",
		ApartmentName: "0730",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170754",
		ApartmentName: "0823",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170755",
		ApartmentName: "0923",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170756",
		ApartmentName: "1023",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170757",
		ApartmentName: "1123",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170758",
		ApartmentName: "1223",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170759",
		ApartmentName: "1323",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170764",
		ApartmentName: "1423",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170765",
		ApartmentName: "1523",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170766",
		ApartmentName: "1623",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170767",
		ApartmentName: "1723",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170768",
		ApartmentName: "1823",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170769",
		ApartmentName: "1923",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170760",
		ApartmentName: "2023",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170761",
		ApartmentName: "2123",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170762",
		ApartmentName: "2223",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041674",
		FloorplanName: "A013",
		ApartmentId: "38170763",
		ApartmentName: "2323",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041675",
		FloorplanName: "A014",
		ApartmentId: "38170770",
		ApartmentName: "0325",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041675",
		FloorplanName: "A014",
		ApartmentId: "38170771",
		ApartmentName: "0425",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041675",
		FloorplanName: "A014",
		ApartmentId: "38170772",
		ApartmentName: "0525",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041675",
		FloorplanName: "A014",
		ApartmentId: "38170773",
		ApartmentName: "0625",
		Beds: "1",
		Baths: "1.00",
		SQFT: "591",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170774",
		ApartmentName: "0733",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170775",
		ApartmentName: "0826",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170785",
		ApartmentName: "0926",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170786",
		ApartmentName: "1028",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170787",
		ApartmentName: "1128",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170788",
		ApartmentName: "1228",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170789",
		ApartmentName: "1328",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170790",
		ApartmentName: "1428",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170779",
		ApartmentName: "1528",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170780",
		ApartmentName: "1628",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170781",
		ApartmentName: "1728",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170782",
		ApartmentName: "1828",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170783",
		ApartmentName: "1928",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170784",
		ApartmentName: "2028",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170776",
		ApartmentName: "2128",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170777",
		ApartmentName: "2228",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041677",
		FloorplanName: "A015",
		ApartmentId: "38170778",
		ApartmentName: "2328",
		Beds: "1",
		Baths: "1.00",
		SQFT: "650",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041678",
		FloorplanName: "A016",
		ApartmentId: "38170791",
		ApartmentName: "0323",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041678",
		FloorplanName: "A016",
		ApartmentId: "38170792",
		ApartmentName: "0423",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041678",
		FloorplanName: "A016",
		ApartmentId: "38170793",
		ApartmentName: "0523",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041678",
		FloorplanName: "A016",
		ApartmentId: "38170794",
		ApartmentName: "0623",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041679",
		FloorplanName: "A017",
		ApartmentId: "38170795",
		ApartmentName: "0330",
		Beds: "1",
		Baths: "1.00",
		SQFT: "659",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041679",
		FloorplanName: "A017",
		ApartmentId: "38170796",
		ApartmentName: "0430",
		Beds: "1",
		Baths: "1.00",
		SQFT: "659",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041679",
		FloorplanName: "A017",
		ApartmentId: "38170797",
		ApartmentName: "0530",
		Beds: "1",
		Baths: "1.00",
		SQFT: "659",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041679",
		FloorplanName: "A017",
		ApartmentId: "38170798",
		ApartmentName: "0630",
		Beds: "1",
		Baths: "1.00",
		SQFT: "659",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041679",
		FloorplanName: "A017",
		ApartmentId: "38170799",
		ApartmentName: "0725",
		Beds: "1",
		Baths: "1.00",
		SQFT: "659",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170800",
		ApartmentName: "0822",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170801",
		ApartmentName: "0922",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170802",
		ApartmentName: "1022",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170803",
		ApartmentName: "1122",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170804",
		ApartmentName: "1222",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170805",
		ApartmentName: "1322",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170810",
		ApartmentName: "1422",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170811",
		ApartmentName: "1522",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170812",
		ApartmentName: "1622",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170813",
		ApartmentName: "1722",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170814",
		ApartmentName: "1822",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170815",
		ApartmentName: "1922",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170806",
		ApartmentName: "2022",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170807",
		ApartmentName: "2122",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170808",
		ApartmentName: "2222",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041680",
		FloorplanName: "A018",
		ApartmentId: "38170809",
		ApartmentName: "2322",
		Beds: "1",
		Baths: "1.00",
		SQFT: "657",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170816",
		ApartmentName: "0326",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170817",
		ApartmentName: "0327",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170818",
		ApartmentName: "0328",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170819",
		ApartmentName: "0329",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170820",
		ApartmentName: "0333",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170821",
		ApartmentName: "0334",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170839",
		ApartmentName: "0426",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170840",
		ApartmentName: "0427",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170841",
		ApartmentName: "0428",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170842",
		ApartmentName: "0429",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170843",
		ApartmentName: "0433",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170844",
		ApartmentName: "0434",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170833",
		ApartmentName: "0526",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170834",
		ApartmentName: "0527",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170835",
		ApartmentName: "0528",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170836",
		ApartmentName: "0529",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170837",
		ApartmentName: "0533",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170838",
		ApartmentName: "0534",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170827",
		ApartmentName: "0626",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170828",
		ApartmentName: "0627",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170829",
		ApartmentName: "0628",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170830",
		ApartmentName: "0629",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170831",
		ApartmentName: "0633",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170832",
		ApartmentName: "0634",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170822",
		ApartmentName: "0722",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170823",
		ApartmentName: "0723",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170824",
		ApartmentName: "0724",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170825",
		ApartmentName: "0728",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041681",
		FloorplanName: "A019",
		ApartmentId: "38170826",
		ApartmentName: "0729",
		Beds: "1",
		Baths: "1.00",
		SQFT: "611",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170666",
		ApartmentName: "0734",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170667",
		ApartmentName: "0827",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170668",
		ApartmentName: "0927",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170669",
		ApartmentName: "1029",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170670",
		ApartmentName: "1129",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170671",
		ApartmentName: "1229",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170677",
		ApartmentName: "1329",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170678",
		ApartmentName: "1429",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170679",
		ApartmentName: "1529",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170680",
		ApartmentName: "1629",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170681",
		ApartmentName: "1729",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170682",
		ApartmentName: "1829",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170672",
		ApartmentName: "1929",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170673",
		ApartmentName: "2029",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170674",
		ApartmentName: "2129",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170675",
		ApartmentName: "2229",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041655",
		FloorplanName: "A02",
		ApartmentId: "38170676",
		ApartmentName: "2329",
		Beds: "1",
		Baths: "1.00",
		SQFT: "537",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041682",
		FloorplanName: "A020",
		ApartmentId: "38170845",
		ApartmentName: "0443",
		Beds: "1",
		Baths: "1.00",
		SQFT: "736",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041682",
		FloorplanName: "A020",
		ApartmentId: "38170846",
		ApartmentName: "0543",
		Beds: "1",
		Baths: "1.00",
		SQFT: "736",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041682",
		FloorplanName: "A020",
		ApartmentId: "38170847",
		ApartmentName: "0643",
		Beds: "1",
		Baths: "1.00",
		SQFT: "736",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041764",
		FloorplanName: "A021",
		ApartmentId: "38170848",
		ApartmentName: "0355",
		Beds: "1",
		Baths: "1.00",
		SQFT: "631",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041764",
		FloorplanName: "A021",
		ApartmentId: "38170849",
		ApartmentName: "0457",
		Beds: "1",
		Baths: "1.00",
		SQFT: "631",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041764",
		FloorplanName: "A021",
		ApartmentId: "38170850",
		ApartmentName: "0557",
		Beds: "1",
		Baths: "1.00",
		SQFT: "631",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041764",
		FloorplanName: "A021",
		ApartmentId: "38170851",
		ApartmentName: "0651",
		Beds: "1",
		Baths: "1.00",
		SQFT: "631",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170852",
		ApartmentName: "0721",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170853",
		ApartmentName: "0821",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170854",
		ApartmentName: "0921",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170855",
		ApartmentName: "1021",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170856",
		ApartmentName: "1121",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170857",
		ApartmentName: "1221",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170863",
		ApartmentName: "1321",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170864",
		ApartmentName: "1421",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170865",
		ApartmentName: "1521",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170866",
		ApartmentName: "1621",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170867",
		ApartmentName: "1721",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170868",
		ApartmentName: "1821",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170858",
		ApartmentName: "1921",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170859",
		ApartmentName: "2021",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170860",
		ApartmentName: "2121",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170861",
		ApartmentName: "2221",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041765",
		FloorplanName: "A022",
		ApartmentId: "38170862",
		ApartmentName: "2321",
		Beds: "1",
		Baths: "1.00",
		SQFT: "637",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041768",
		FloorplanName: "A023",
		ApartmentId: "38170869",
		ApartmentName: "0653",
		Beds: "1",
		Baths: "1.00",
		SQFT: "633",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041772",
		FloorplanName: "A024",
		ApartmentId: "38170870",
		ApartmentName: "0322",
		Beds: "1",
		Baths: "1.00",
		SQFT: "671",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041772",
		FloorplanName: "A024",
		ApartmentId: "38170871",
		ApartmentName: "0422",
		Beds: "1",
		Baths: "1.00",
		SQFT: "671",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041772",
		FloorplanName: "A024",
		ApartmentId: "38170872",
		ApartmentName: "0522",
		Beds: "1",
		Baths: "1.00",
		SQFT: "671",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041772",
		FloorplanName: "A024",
		ApartmentId: "38170873",
		ApartmentName: "0622",
		Beds: "1",
		Baths: "1.00",
		SQFT: "671",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170874",
		ApartmentName: "0732",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170875",
		ApartmentName: "0825",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170876",
		ApartmentName: "0925",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170877",
		ApartmentName: "1027",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170878",
		ApartmentName: "1127",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170879",
		ApartmentName: "1227",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170885",
		ApartmentName: "1327",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170886",
		ApartmentName: "1427",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170887",
		ApartmentName: "1527",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170888",
		ApartmentName: "1627",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170889",
		ApartmentName: "1727",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170890",
		ApartmentName: "1827",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170880",
		ApartmentName: "1927",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170881",
		ApartmentName: "2027",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170882",
		ApartmentName: "2127",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170883",
		ApartmentName: "2227",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041784",
		FloorplanName: "A025",
		ApartmentId: "38170884",
		ApartmentName: "2327",
		Beds: "1",
		Baths: "1.00",
		SQFT: "668",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041786",
		FloorplanName: "A026",
		ApartmentId: "38170891",
		ApartmentName: "0336",
		Beds: "1",
		Baths: "1.00",
		SQFT: "718",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041786",
		FloorplanName: "A026",
		ApartmentId: "38170892",
		ApartmentName: "0438",
		Beds: "1",
		Baths: "1.00",
		SQFT: "718",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041786",
		FloorplanName: "A026",
		ApartmentId: "38170893",
		ApartmentName: "0538",
		Beds: "1",
		Baths: "1.00",
		SQFT: "718",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041786",
		FloorplanName: "A026",
		ApartmentId: "38170894",
		ApartmentName: "0638",
		Beds: "1",
		Baths: "1.00",
		SQFT: "718",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041789",
		FloorplanName: "A027P",
		ApartmentId: "38170895",
		ApartmentName: "PH1C",
		Beds: "1",
		Baths: "1.00",
		SQFT: "607",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041789",
		FloorplanName: "A027P",
		ApartmentId: "38170896",
		ApartmentName: "PH2B",
		Beds: "1",
		Baths: "1.00",
		SQFT: "607",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041789",
		FloorplanName: "A027P",
		ApartmentId: "38170897",
		ApartmentName: "PH3B",
		Beds: "1",
		Baths: "1.00",
		SQFT: "607",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041791",
		FloorplanName: "A028",
		ApartmentId: "38170898",
		ApartmentName: "0437",
		Beds: "1",
		Baths: "1.00",
		SQFT: "694",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041791",
		FloorplanName: "A028",
		ApartmentId: "38170899",
		ApartmentName: "0537",
		Beds: "1",
		Baths: "1.00",
		SQFT: "694",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041791",
		FloorplanName: "A028",
		ApartmentId: "38170900",
		ApartmentName: "0637",
		Beds: "1",
		Baths: "1.00",
		SQFT: "694",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170901",
		ApartmentName: "0705",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170902",
		ApartmentName: "0805",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170903",
		ApartmentName: "0905",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170904",
		ApartmentName: "1005",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170905",
		ApartmentName: "1105",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170906",
		ApartmentName: "1205",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170907",
		ApartmentName: "1305",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170908",
		ApartmentName: "1405",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170909",
		ApartmentName: "1505",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064554",
		FloorplanName: "A029f",
		ApartmentId: "38170910",
		ApartmentName: "1605",
		Beds: "1",
		Baths: "1.00",
		SQFT: "459",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170683",
		ApartmentName: "1025",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170684",
		ApartmentName: "1125",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170685",
		ApartmentName: "1225",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170686",
		ApartmentName: "1325",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170687",
		ApartmentName: "1425",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170688",
		ApartmentName: "1525",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170691",
		ApartmentName: "1625",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170692",
		ApartmentName: "1725",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170693",
		ApartmentName: "1825",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170694",
		ApartmentName: "1925",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170695",
		ApartmentName: "2025",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170696",
		ApartmentName: "2125",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170689",
		ApartmentName: "2225",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041656",
		FloorplanName: "A03",
		ApartmentId: "38170690",
		ApartmentName: "2325",
		Beds: "1",
		Baths: "1.00",
		SQFT: "573",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170911",
		ApartmentName: "0706",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170912",
		ApartmentName: "0806",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170913",
		ApartmentName: "0906",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170914",
		ApartmentName: "1006",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170915",
		ApartmentName: "1106",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170916",
		ApartmentName: "1206",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170917",
		ApartmentName: "1306",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170918",
		ApartmentName: "1406",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170919",
		ApartmentName: "1506",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064558",
		FloorplanName: "A030f",
		ApartmentId: "38170920",
		ApartmentName: "1606",
		Beds: "1",
		Baths: "1.00",
		SQFT: "513",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170921",
		ApartmentName: "0701",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170922",
		ApartmentName: "0801",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170923",
		ApartmentName: "0901",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170924",
		ApartmentName: "1001",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170925",
		ApartmentName: "1101",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170926",
		ApartmentName: "1201",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170927",
		ApartmentName: "1301",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170928",
		ApartmentName: "1401",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170929",
		ApartmentName: "1501",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064561",
		FloorplanName: "A031f",
		ApartmentId: "38170930",
		ApartmentName: "1601",
		Beds: "1",
		Baths: "1.00",
		SQFT: "517",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064562",
		FloorplanName: "A032f",
		ApartmentId: "38170931",
		ApartmentName: "0410",
		Beds: "1",
		Baths: "1.00",
		SQFT: "523",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064562",
		FloorplanName: "A032f",
		ApartmentId: "38170932",
		ApartmentName: "0510",
		Beds: "1",
		Baths: "1.00",
		SQFT: "523",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064562",
		FloorplanName: "A032f",
		ApartmentId: "38170933",
		ApartmentName: "0610",
		Beds: "1",
		Baths: "1.00",
		SQFT: "523",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064565",
		FloorplanName: "A033f",
		ApartmentId: "38170934",
		ApartmentName: "0310",
		Beds: "1",
		Baths: "1.00",
		SQFT: "523",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064568",
		FloorplanName: "A034f",
		ApartmentId: "38170935",
		ApartmentName: "0311",
		Beds: "1",
		Baths: "1.00",
		SQFT: "529",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064568",
		FloorplanName: "A034f",
		ApartmentId: "38170936",
		ApartmentName: "0411",
		Beds: "1",
		Baths: "1.00",
		SQFT: "529",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064568",
		FloorplanName: "A034f",
		ApartmentId: "38170937",
		ApartmentName: "0511",
		Beds: "1",
		Baths: "1.00",
		SQFT: "529",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064568",
		FloorplanName: "A034f",
		ApartmentId: "38170938",
		ApartmentName: "0611",
		Beds: "1",
		Baths: "1.00",
		SQFT: "529",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170939",
		ApartmentName: "0203",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170940",
		ApartmentName: "0204",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170941",
		ApartmentName: "0205",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170942",
		ApartmentName: "0206",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170943",
		ApartmentName: "0207",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170944",
		ApartmentName: "0303",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170958",
		ApartmentName: "0304",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170959",
		ApartmentName: "0305",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170960",
		ApartmentName: "0306",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170961",
		ApartmentName: "0307",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170962",
		ApartmentName: "0403",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170963",
		ApartmentName: "0404",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170952",
		ApartmentName: "0405",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170953",
		ApartmentName: "0406",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170954",
		ApartmentName: "0407",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170955",
		ApartmentName: "0503",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170956",
		ApartmentName: "0504",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170957",
		ApartmentName: "0505",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170946",
		ApartmentName: "0506",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170947",
		ApartmentName: "0507",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170948",
		ApartmentName: "0603",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170949",
		ApartmentName: "0604",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170950",
		ApartmentName: "0605",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170951",
		ApartmentName: "0606",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064569",
		FloorplanName: "A035f",
		ApartmentId: "38170945",
		ApartmentName: "0607",
		Beds: "1",
		Baths: "1.00",
		SQFT: "642",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064572",
		FloorplanName: "A036f",
		ApartmentId: "38170964",
		ApartmentName: "0302",
		Beds: "1",
		Baths: "1.00",
		SQFT: "644",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064572",
		FloorplanName: "A036f",
		ApartmentId: "38170965",
		ApartmentName: "0402",
		Beds: "1",
		Baths: "1.00",
		SQFT: "644",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064574",
		FloorplanName: "A037f",
		ApartmentId: "38170966",
		ApartmentName: "0202",
		Beds: "1",
		Baths: "1.00",
		SQFT: "646",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064574",
		FloorplanName: "A037f",
		ApartmentId: "38170967",
		ApartmentName: "0502",
		Beds: "1",
		Baths: "1.00",
		SQFT: "646",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064574",
		FloorplanName: "A037f",
		ApartmentId: "38170968",
		ApartmentName: "0602",
		Beds: "1",
		Baths: "1.00",
		SQFT: "646",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170969",
		ApartmentName: "0312",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170970",
		ApartmentName: "0313",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170971",
		ApartmentName: "0412",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170972",
		ApartmentName: "0413",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170973",
		ApartmentName: "0512",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170974",
		ApartmentName: "0513",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170975",
		ApartmentName: "0612",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064576",
		FloorplanName: "A038f",
		ApartmentId: "38170976",
		ApartmentName: "0613",
		Beds: "1",
		Baths: "1.00",
		SQFT: "648",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064579",
		FloorplanName: "A039f",
		ApartmentId: "38170977",
		ApartmentName: "0314",
		Beds: "1",
		Baths: "1.00",
		SQFT: "656",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064579",
		FloorplanName: "A039f",
		ApartmentId: "38170978",
		ApartmentName: "0414",
		Beds: "1",
		Baths: "1.00",
		SQFT: "656",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041659",
		FloorplanName: "A04",
		ApartmentId: "38170697",
		ApartmentName: "0324",
		Beds: "1",
		Baths: "1.00",
		SQFT: "564",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041659",
		FloorplanName: "A04",
		ApartmentId: "38170698",
		ApartmentName: "0424",
		Beds: "1",
		Baths: "1.00",
		SQFT: "564",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041659",
		FloorplanName: "A04",
		ApartmentId: "38170699",
		ApartmentName: "0524",
		Beds: "1",
		Baths: "1.00",
		SQFT: "564",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041659",
		FloorplanName: "A04",
		ApartmentId: "38170700",
		ApartmentName: "0624",
		Beds: "1",
		Baths: "1.00",
		SQFT: "564",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064580",
		FloorplanName: "A040f",
		ApartmentId: "38170979",
		ApartmentName: "0514",
		Beds: "1",
		Baths: "1.00",
		SQFT: "656",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064580",
		FloorplanName: "A040f",
		ApartmentId: "38170980",
		ApartmentName: "0614",
		Beds: "1",
		Baths: "1.00",
		SQFT: "656",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170981",
		ApartmentName: "0702",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170982",
		ApartmentName: "0802",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170983",
		ApartmentName: "0902",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170984",
		ApartmentName: "1002",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170985",
		ApartmentName: "1102",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170986",
		ApartmentName: "1202",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170987",
		ApartmentName: "1302",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170988",
		ApartmentName: "1402",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170989",
		ApartmentName: "1502",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064582",
		FloorplanName: "A041f",
		ApartmentId: "38170990",
		ApartmentName: "1602",
		Beds: "1",
		Baths: "1.00",
		SQFT: "675",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064588",
		FloorplanName: "A042f",
		ApartmentId: "38170991",
		ApartmentName: "0315",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170992",
		ApartmentName: "0704",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170993",
		ApartmentName: "0804",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170994",
		ApartmentName: "0904",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170995",
		ApartmentName: "1004",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170996",
		ApartmentName: "1104",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170997",
		ApartmentName: "1204",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170998",
		ApartmentName: "1304",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38170999",
		ApartmentName: "1404",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38171000",
		ApartmentName: "1504",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064589",
		FloorplanName: "A043f",
		ApartmentId: "38171001",
		ApartmentName: "1604",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064590",
		FloorplanName: "A044f",
		ApartmentId: "38171002",
		ApartmentName: "0415",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064590",
		FloorplanName: "A044f",
		ApartmentId: "38171003",
		ApartmentName: "0515",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064590",
		FloorplanName: "A044f",
		ApartmentId: "38171004",
		ApartmentName: "0615",
		Beds: "1",
		Baths: "1.00",
		SQFT: "678",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171005",
		ApartmentName: "0707",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171006",
		ApartmentName: "0807",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171007",
		ApartmentName: "0907",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171008",
		ApartmentName: "1007",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171009",
		ApartmentName: "1107",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171010",
		ApartmentName: "1207",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171011",
		ApartmentName: "1307",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171012",
		ApartmentName: "1407",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171013",
		ApartmentName: "1507",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064593",
		FloorplanName: "A045f",
		ApartmentId: "38171014",
		ApartmentName: "1607",
		Beds: "1",
		Baths: "1.00",
		SQFT: "685",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064595",
		FloorplanName: "A046f",
		ApartmentId: "38171015",
		ApartmentName: "0208",
		Beds: "1",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064595",
		FloorplanName: "A046f",
		ApartmentId: "38171016",
		ApartmentName: "0308",
		Beds: "1",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064595",
		FloorplanName: "A046f",
		ApartmentId: "38171017",
		ApartmentName: "0408",
		Beds: "1",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064595",
		FloorplanName: "A046f",
		ApartmentId: "38171018",
		ApartmentName: "0508",
		Beds: "1",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064595",
		FloorplanName: "A046f",
		ApartmentId: "38171019",
		ApartmentName: "0608",
		Beds: "1",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171020",
		ApartmentName: "0703",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171021",
		ApartmentName: "0803",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171022",
		ApartmentName: "0903",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171023",
		ApartmentName: "1003",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171024",
		ApartmentName: "1103",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171025",
		ApartmentName: "1203",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171026",
		ApartmentName: "1303",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171027",
		ApartmentName: "1403",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171028",
		ApartmentName: "1503",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064597",
		FloorplanName: "A047f",
		ApartmentId: "38171029",
		ApartmentName: "1603",
		Beds: "1",
		Baths: "1.00",
		SQFT: "696",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170701",
		ApartmentName: "0731",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170702",
		ApartmentName: "0824",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170703",
		ApartmentName: "0924",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170704",
		ApartmentName: "1026",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170705",
		ApartmentName: "1126",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170706",
		ApartmentName: "1226",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170712",
		ApartmentName: "1326",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170713",
		ApartmentName: "1426",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170714",
		ApartmentName: "1526",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170715",
		ApartmentName: "1626",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170716",
		ApartmentName: "1726",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170717",
		ApartmentName: "1826",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170707",
		ApartmentName: "1926",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170708",
		ApartmentName: "2026",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170709",
		ApartmentName: "2126",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170710",
		ApartmentName: "2226",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041662",
		FloorplanName: "A05",
		ApartmentId: "38170711",
		ApartmentName: "2326",
		Beds: "1",
		Baths: "1.00",
		SQFT: "609",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041665",
		FloorplanName: "A06",
		ApartmentId: "38170718",
		ApartmentName: "0332",
		Beds: "1",
		Baths: "1.00",
		SQFT: "558",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041665",
		FloorplanName: "A06",
		ApartmentId: "38170719",
		ApartmentName: "0432",
		Beds: "1",
		Baths: "1.00",
		SQFT: "558",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041665",
		FloorplanName: "A06",
		ApartmentId: "38170720",
		ApartmentName: "0532",
		Beds: "1",
		Baths: "1.00",
		SQFT: "558",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041665",
		FloorplanName: "A06",
		ApartmentId: "38170721",
		ApartmentName: "0632",
		Beds: "1",
		Baths: "1.00",
		SQFT: "558",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041665",
		FloorplanName: "A06",
		ApartmentId: "38170722",
		ApartmentName: "0727",
		Beds: "1",
		Baths: "1.00",
		SQFT: "558",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041667",
		FloorplanName: "A07",
		ApartmentId: "38170723",
		ApartmentName: "0341",
		Beds: "1",
		Baths: "1.00",
		SQFT: "595",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041667",
		FloorplanName: "A07",
		ApartmentId: "38170724",
		ApartmentName: "0342",
		Beds: "1",
		Baths: "1.00",
		SQFT: "595",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041667",
		FloorplanName: "A07",
		ApartmentId: "38170725",
		ApartmentName: "0444",
		Beds: "1",
		Baths: "1.00",
		SQFT: "595",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041667",
		FloorplanName: "A07",
		ApartmentId: "38170726",
		ApartmentName: "0544",
		Beds: "1",
		Baths: "1.00",
		SQFT: "595",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041668",
		FloorplanName: "A08",
		ApartmentId: "38170727",
		ApartmentName: "0343",
		Beds: "1",
		Baths: "1.00",
		SQFT: "629",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041668",
		FloorplanName: "A08",
		ApartmentId: "38170728",
		ApartmentName: "0445",
		Beds: "1",
		Baths: "1.00",
		SQFT: "629",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041668",
		FloorplanName: "A08",
		ApartmentId: "38170729",
		ApartmentName: "0545",
		Beds: "1",
		Baths: "1.00",
		SQFT: "629",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170730",
		ApartmentName: "0337",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170731",
		ApartmentName: "0338",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170732",
		ApartmentName: "0339",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170733",
		ApartmentName: "0439",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170734",
		ApartmentName: "0440",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170735",
		ApartmentName: "0441",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170736",
		ApartmentName: "0539",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170737",
		ApartmentName: "0540",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170738",
		ApartmentName: "0541",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170739",
		ApartmentName: "0639",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170740",
		ApartmentName: "0640",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041670",
		FloorplanName: "A09",
		ApartmentId: "38170741",
		ApartmentName: "0641",
		Beds: "1",
		Baths: "1.00",
		SQFT: "579",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095627",
		FloorplanName: "B01 - Jr 2 Bedroom",
		ApartmentId: "38171030",
		ApartmentName: "0230",
		Beds: "2",
		Baths: "1.00",
		SQFT: "802",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095627",
		FloorplanName: "B01 - Jr 2 Bedroom",
		ApartmentId: "38171031",
		ApartmentName: "0344",
		Beds: "2",
		Baths: "1.00",
		SQFT: "802",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095627",
		FloorplanName: "B01 - Jr 2 Bedroom",
		ApartmentId: "38171032",
		ApartmentName: "0446",
		Beds: "2",
		Baths: "1.00",
		SQFT: "802",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095627",
		FloorplanName: "B01 - Jr 2 Bedroom",
		ApartmentId: "38171033",
		ApartmentName: "0546",
		Beds: "2",
		Baths: "1.00",
		SQFT: "802",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095630",
		FloorplanName: "B02 - Jr 2 Bedroom",
		ApartmentId: "38171034",
		ApartmentName: "0321",
		Beds: "2",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095630",
		FloorplanName: "B02 - Jr 2 Bedroom",
		ApartmentId: "38171035",
		ApartmentName: "0421",
		Beds: "2",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095630",
		FloorplanName: "B02 - Jr 2 Bedroom",
		ApartmentId: "38171036",
		ApartmentName: "0521",
		Beds: "2",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095630",
		FloorplanName: "B02 - Jr 2 Bedroom",
		ApartmentId: "38171037",
		ApartmentName: "0621",
		Beds: "2",
		Baths: "1.00",
		SQFT: "688",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095632",
		FloorplanName: "B03 - Jr 2 Bedroom",
		ApartmentId: "38171038",
		ApartmentName: "0229",
		Beds: "2",
		Baths: "1.00",
		SQFT: "612",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095632",
		FloorplanName: "B03 - Jr 2 Bedroom",
		ApartmentId: "38171039",
		ApartmentName: "0345",
		Beds: "2",
		Baths: "1.00",
		SQFT: "612",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095632",
		FloorplanName: "B03 - Jr 2 Bedroom",
		ApartmentId: "38171040",
		ApartmentName: "0447",
		Beds: "2",
		Baths: "1.00",
		SQFT: "612",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095632",
		FloorplanName: "B03 - Jr 2 Bedroom",
		ApartmentId: "38171041",
		ApartmentName: "0547",
		Beds: "2",
		Baths: "1.00",
		SQFT: "612",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171042",
		ApartmentName: "0221",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171043",
		ApartmentName: "0222",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171044",
		ApartmentName: "0223",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171045",
		ApartmentName: "0224",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171046",
		ApartmentName: "0225",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171047",
		ApartmentName: "0226",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171078",
		ApartmentName: "0227",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171079",
		ApartmentName: "0228",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171080",
		ApartmentName: "0346",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171081",
		ApartmentName: "0347",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171082",
		ApartmentName: "0348",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171083",
		ApartmentName: "0349",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171072",
		ApartmentName: "0350",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171073",
		ApartmentName: "0351",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171074",
		ApartmentName: "0352",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171075",
		ApartmentName: "0353",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171076",
		ApartmentName: "0354",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171077",
		ApartmentName: "0448",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171066",
		ApartmentName: "0449",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171067",
		ApartmentName: "0450",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171068",
		ApartmentName: "0451",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171069",
		ApartmentName: "0452",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171070",
		ApartmentName: "0453",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171071",
		ApartmentName: "0454",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171060",
		ApartmentName: "0455",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171061",
		ApartmentName: "0456",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171062",
		ApartmentName: "0548",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171063",
		ApartmentName: "0549",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171064",
		ApartmentName: "0550",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171065",
		ApartmentName: "0551",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171054",
		ApartmentName: "0552",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171055",
		ApartmentName: "0553",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171056",
		ApartmentName: "0554",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171057",
		ApartmentName: "0555",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171058",
		ApartmentName: "0556",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171059",
		ApartmentName: "0644",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171048",
		ApartmentName: "0645",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171049",
		ApartmentName: "0646",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171050",
		ApartmentName: "0647",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171051",
		ApartmentName: "0648",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171052",
		ApartmentName: "0649",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095633",
		FloorplanName: "B04 - Jr 2 Bedroom",
		ApartmentId: "38171053",
		ApartmentName: "0650",
		Beds: "2",
		Baths: "1.00",
		SQFT: "628",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095635",
		FloorplanName: "B05 - Jr 2 Bedroom",
		ApartmentId: "38171084",
		ApartmentName: "0356",
		Beds: "2",
		Baths: "1.00",
		SQFT: "621",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095635",
		FloorplanName: "B05 - Jr 2 Bedroom",
		ApartmentId: "38171085",
		ApartmentName: "0458",
		Beds: "2",
		Baths: "1.00",
		SQFT: "621",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095635",
		FloorplanName: "B05 - Jr 2 Bedroom",
		ApartmentId: "38171086",
		ApartmentName: "0558",
		Beds: "2",
		Baths: "1.00",
		SQFT: "621",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095635",
		FloorplanName: "B05 - Jr 2 Bedroom",
		ApartmentId: "38171087",
		ApartmentName: "0652",
		Beds: "2",
		Baths: "1.00",
		SQFT: "621",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5095636",
		FloorplanName: "B06 - Jr 2 Bedroom",
		ApartmentId: "38171088",
		ApartmentName: "0654",
		Beds: "2",
		Baths: "1.00",
		SQFT: "620",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171089",
		ApartmentName: "1024",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171090",
		ApartmentName: "1124",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171091",
		ApartmentName: "1224",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171092",
		ApartmentName: "1324",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171093",
		ApartmentName: "1424",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171094",
		ApartmentName: "1524",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171097",
		ApartmentName: "1624",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171098",
		ApartmentName: "1724",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171099",
		ApartmentName: "1824",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171100",
		ApartmentName: "1924",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171101",
		ApartmentName: "2024",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171102",
		ApartmentName: "2124",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171095",
		ApartmentName: "2224",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041802",
		FloorplanName: "C01",
		ApartmentId: "38171096",
		ApartmentName: "2324",
		Beds: "2",
		Baths: "1.00",
		SQFT: "807",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064605",
		FloorplanName: "C010f",
		ApartmentId: "38171117",
		ApartmentName: "0609",
		Beds: "2",
		Baths: "1.00",
		SQFT: "894",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064607",
		FloorplanName: "C011f",
		ApartmentId: "38171118",
		ApartmentName: "0309",
		Beds: "2",
		Baths: "1.00",
		SQFT: "894",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064608",
		FloorplanName: "C012f",
		ApartmentId: "38171119",
		ApartmentName: "0201",
		Beds: "2",
		Baths: "1.00",
		SQFT: "900",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064608",
		FloorplanName: "C012f",
		ApartmentId: "38171120",
		ApartmentName: "0301",
		Beds: "2",
		Baths: "1.00",
		SQFT: "900",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064608",
		FloorplanName: "C012f",
		ApartmentId: "38171121",
		ApartmentName: "0401",
		Beds: "2",
		Baths: "1.00",
		SQFT: "900",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064608",
		FloorplanName: "C012f",
		ApartmentId: "38171122",
		ApartmentName: "0501",
		Beds: "2",
		Baths: "1.00",
		SQFT: "900",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064608",
		FloorplanName: "C012f",
		ApartmentId: "38171123",
		ApartmentName: "0601",
		Beds: "2",
		Baths: "1.00",
		SQFT: "900",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041804",
		FloorplanName: "C03P",
		ApartmentId: "38171105",
		ApartmentName: "PH1D",
		Beds: "2",
		Baths: "1.00",
		SQFT: "876",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041804",
		FloorplanName: "C03P",
		ApartmentId: "38171106",
		ApartmentName: "PH2C",
		Beds: "2",
		Baths: "1.00",
		SQFT: "876",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041804",
		FloorplanName: "C03P",
		ApartmentId: "38171104",
		ApartmentName: "PH3C",
		Beds: "2",
		Baths: "1.00",
		SQFT: "876",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041806",
		FloorplanName: "C04P",
		ApartmentId: "38171107",
		ApartmentName: "PH1E",
		Beds: "2",
		Baths: "2.00",
		SQFT: "920",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041806",
		FloorplanName: "C04P",
		ApartmentId: "38171108",
		ApartmentName: "PH2D",
		Beds: "2",
		Baths: "2.00",
		SQFT: "920",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041806",
		FloorplanName: "C04P",
		ApartmentId: "38171109",
		ApartmentName: "PH3D",
		Beds: "2",
		Baths: "2.00",
		SQFT: "920",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041807",
		FloorplanName: "C05P",
		ApartmentId: "38171110",
		ApartmentName: "PH1A",
		Beds: "2",
		Baths: "2.00",
		SQFT: "1352",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041807",
		FloorplanName: "C05P",
		ApartmentId: "38171111",
		ApartmentName: "PH2A",
		Beds: "2",
		Baths: "2.00",
		SQFT: "1352",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041807",
		FloorplanName: "C05P",
		ApartmentId: "38171112",
		ApartmentName: "PH3A",
		Beds: "2",
		Baths: "2.00",
		SQFT: "1352",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5041808",
		FloorplanName: "C06P",
		ApartmentId: "38171113",
		ApartmentName: "PH1B",
		Beds: "2",
		Baths: "2.00",
		SQFT: "957",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064599",
		FloorplanName: "C07f",
		ApartmentId: "38171114",
		ApartmentName: "0209",
		Beds: "2",
		Baths: "1.00",
		SQFT: "888",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064600",
		FloorplanName: "C08f",
		ApartmentId: "38171115",
		ApartmentName: "0409",
		Beds: "2",
		Baths: "1.00",
		SQFT: "894",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	},
	{
		PropertyId: "1723756",
		VoyagerPropertyId: "2025",
		VoyagerPropertyCode: "720",
		FloorplanId: "5064604",
		FloorplanName: "C09f",
		ApartmentId: "38171116",
		ApartmentName: "0509",
		Beds: "2",
		Baths: "1.00",
		SQFT: "894",
		MinimumRent: "-1",
		MaximumRent: "-1",
		Deposit: "0",
		ApplyOnlineURL: "",
		UnitImageURLs: [""],
		UnitImageAltText: "",
		Specials: "",
		Amenities: "",
		AvailableDate: "1/30/2024",
		UnitStatus: "Vacant Unrented Not Ready"
	}
]

export default backUpData
