import styled from "styled-components"

import TSVGPropTypes from "@customTypes/SVGPropTypes"

function ScrollIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<SVG
			xmlns="http://www.w3.org/2000/SVG"
			viewBox="0 0 100 250"
			width="100%"
			height="100%"
			{...props}>
			<polyline
				points="0 241 5 247 10 241"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1={5}
				y1={5}
				x2={5}
				y2={247}
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<text fill={strokeColor} fontSize={100} textLength={88} x={12} y={80}>SC</text>
			<text fill={strokeColor} fontSize={100} textLength={88} x={12} y={165}>RO</text>
			<text fill={strokeColor} fontSize={100} textLength={88} x={12} y={249}>LL</text>
		</SVG>
	)
}

export default ScrollIcon

const SVG = styled.svg`
  & text{
    font-family: title;
  }
`
