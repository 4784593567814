import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"

import LoadingIcon from "@components/SVGIcons/LoadingIcon/LoadingIcon"
import EmailTemplate from "@components/EmailTemplate"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import { validation } from "@utils/validation"

type TForm = {
  name: string
  surname: string
  email: string
  link?: string
}

const initialForm = {
  name: null,
  surname: null,
  email: null,
}

function Form() {
  const favorites = useStore((s) => s.favorites)
  const promptRef = useRef<HTMLDivElement>()
  const [formData, setFormData] = useState<TForm | null>(initialForm)
  const [formMessage, setFormMessage] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)
  const [sending, setSending] = useState(false)
  const encodedFav = btoa(`${favorites?.join(",")}`)
  const url = `https://share.1515surf.app?${encodedFav}`

  function resetPrompt() {
    promptRef.current.style.opacity = "0"
    setTimeout(() => {
      setFormMessage("")
    }, 800)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = document.getElementById("form") as HTMLFormElement
    const errors = validation(formData)
    if (errors !== undefined) {
      setFormMessage(errors)
      promptRef.current.style.opacity = "1"
    } else {
      setFormData({ ...formData, link: url })
      setSending(true)
      setReadyToSend(true)
      form.reset()
    }
  }

  useEffect(() => {
    if (formData && readyToSend) {
      
      const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate
          link={url}
        />
      )
      fetch(`https://api-gateway.vmiservers.com/mailer/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          from: `Leasing at 1515 Surf <leasing@1515surf.app>`,
          html: htmlTemplate,
          subject: `Hi ${formData.name} ${formData.surname}, Here are your favorites.`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setReadyToSend(false)
            setFormData(initialForm)
            setSending(false)
            setFormMessage("Email sent")
            promptRef.current.style.opacity = "1"
          }
        })
        .catch((err) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email failed to send. Pleas try again later.")
          promptRef.current.style.opacity = "1"
          console.error(
            "There has been a problem with your fetch operation:",
            err
          )
        })
    }
    return () => {
      setReadyToSend(false)
      setFormMessage("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSend])

  return (
    <FormWrapper>
      <h1>
        Share Your<br />
        Favorite Residences
      </h1>
      <h6 ref={promptRef}>{formMessage}</h6>
      <form id="form">
        <input
          placeholder="First Name"
          name="fName"
          type="text"
          max="20"
          onInput={(e) => {
            setFormData({
              ...formData,
              name: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
        />

        <input
          placeholder="Last Name"
          name="lName"
          type="text"
          max="20"
          onInput={(e) => {
            setFormData({
              ...formData,
              surname: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
        />

        <input
          placeholder="Email"
          name="Email"
          type="Email"
          max="30"
          onInput={(e) => {
            setFormData({
              ...formData,
              email: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
        />
        <button
          className="Email"
          onClick={(e) => {
            handleSubmit(e)
          }}
        >
          {sending ? (
            <LoadingIcon color={globalStyles.colors.main} />
          ) : (
            "SUBMIT"
          )}
        </button>
      </form>
    </FormWrapper>
  )
}

export default Form

const FormWrapper = styled.div`
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  color: ${globalStyles.colors.main};
  
  & h1 {
    width: 100%;
    height: 30%;
    font-family: title;
    text-align: center;
    font-size: 2.5rem;
    padding: 5%;
    letter-spacing: 0.5rem;
    /* transform: translateX(10%); */

    & span {
      font-family: titleLight;
      font-size: 1.5rem;
    }
  }
  
  & h6 {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 1.5rem;
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
    font-family: titleBold;
  }

  & form {
    color: ${globalStyles.colors.main};
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    & input {
      width: 80%;
      height: 2rem;
      border: none;
      border-bottom: 0.1rem solid ${globalStyles.colors.main};
      border-radius: 0;
      background-color: transparent;
      color: ${globalStyles.colors.main};
      font-size: 1.3rem;
      pointer-events: all;
      font-family: titleLight;

      ::placeholder {
        color: ${globalStyles.colors.main};
        letter-spacing: 0.2rem;
        font-size: 1.3rem;
        opacity: 0.8;
      }
      :focus-visible {
        outline: none;
        background-color: transparent;
      }
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        color: ${globalStyles.colors.main} !important;
      }
    }

    & button {
      background: none;
      border: none;
      font-size: 1.5rem;
      color: ${globalStyles.colors.black};
      width: 50%;
      height: 3.5rem;
      max-height: 3.5rem;
      /* transform: translateX(20%); */
      pointer-events: all;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: title;
      font-size: 2.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.65625rem;
    }
  }
`
