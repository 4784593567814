export const MAP_POI_CATEGORIES = [
	{
		id: "surroundingArea",
		title: "Surrounding Area",
		pois: [
			{
				poi: "Adventurers Park",
				coordinates: [-74.000501, 40.60185],
				description: "",
				address: ""
			},
			{
				poi: "Sheepshead Bay",
				coordinates: [-73.94484264036363, 40.59122662029543],
				description: "",
				address: ""
			},
			{
				poi: "Coney Island Bay",
				coordinates: [-74.0069400760585, 40.581746918699025],
				description: "",
				address: ""
			},
			{
				poi: "Marine Park",
				coordinates: [-73.92614823070659, 40.59683294147183],
				description: "",
				address: ""
			},
			{
				poi: "Dyker Beach Park",
				coordinates: [-74.01875, 40.61177],
				description: "",
				address: ""
			},
			{
				poi: "Gravesend",
				coordinates: [-73.975, 40.5968],
				description: "",
				address: ""
			}
		]
	},
	{
		id: "localNeighborhood",
		title: "Local Neighborhood",
		pois: [
			{
				poi: "Coney Island Beach",
				coordinates: [-73.9793, 40.5721],
				description: "",
				address: ""
			},
			{
				poi: "New York Aquarium",
				coordinates: [-73.97593, 40.5741],
				description: "",
				address: ""
			},
			{
				poi: "MCU Park",
				coordinates: [-73.9844, 40.5747],
				description: "",
				address: ""
			}
		]
	}
]
export const mapData = {
	surf: [
		{
			type: "point",
			coords: [-73.98384264036363, 40.5770266202954],
			name: "1515 Surf"
		}
	],
	dining: [
		{
			name: "Margarita Island",
			type: "point",
			coords: [-73.979237, 40.574816],
			zoom: 13,
			index: 0,
			poiNumber: 1
		},
		{
			name: "Tatiana Grill",
			type: "point",
			coords: [-73.962699, 40.575385],
			zoom: 13,
			index: 1,
			poiNumber: 2
		},
		{
			name: "White Castle",
			type: "point",
			coords: [-73.980757, 40.574921],
			zoom: 13,
			index: 2,
			poiNumber: 3
		},
		{
			name: "Paul’s Daughter",
			type: "point",
			coords: [-73.978389, 40.57347],
			zoom: 13,
			index: 3,
			poiNumber: 4
		},
		{
			name: "Anhor Cafe",
			type: "point",
			coords: [-73.989277, 40.57876],
			zoom: 13,
			index: 4,
			poiNumber: 5
		},
		{
			name: "Kitchen 21",
			type: "point",
			coords: [-73.987549, 40.572638],
			zoom: 13,
			index: 5,
			poiNumber: 6
		},
		{
			name: "Doña Zita",
			type: "point",
			coords: [-73.980405, 40.574903],
			zoom: 13,
			index: 6,
			poiNumber: 7
		},
		{
			name: "Parkview Diner",
			type: "point",
			coords: [-73.986104, 40.583426],
			zoom: 13,
			index: 7,
			poiNumber: 8
		},
		{
			name: "Nathan’s Famous",
			type: "point",
			coords: [-73.977882, 40.575648],
			zoom: 13,
			index: 8,
			poiNumber: 9
		},
		{
			name: "Tom’s Coney Island",
			type: "point",
			coords: [-73.980589, 40.573318],
			zoom: 13,
			index: 9,
			poiNumber: 10
		},
		{
			name: "Jimmy’s Famous Heros",
			type: "point",
			coords: [-73.950898, 40.584065],
			zoom: 13,
			index: 10,
			poiNumber: 11
		},
		{
			name: "Totonno’s Pizzeria",
			type: "point",
			coords: [-73.983802, 40.578791],
			zoom: 13,
			index: 11,
			poiNumber: 12
		},
		{
			name: "Georgian Corner",
			type: "point",
			coords: [-73.976004, 40.578937],
			zoom: 13,
			index: 12,
			poiNumber: 13
		},
		{
			name: "Starbucks",
			type: "point",
			coords: [-73.977477, 40.57562],
			zoom: 13,
			index: 13,
			poiNumber: 14
		},
		{
			name: "Nathan’s Famous",
			type: "point",
			coords: [-73.981466, 40.575309],
			zoom: 13,
			index: 14,
			poiNumber: 15
		},
		{
			name: "Ruby’s",
			type: "point",
			coords: [-73.979946, 40.573321],
			zoom: 13,
			index: 15,
			poiNumber: 16
		},
		{
			name: "L&B Spumoni Gardens",
			type: "point",
			coords: [-73.981424, 40.594712],
			zoom: 13,
			index: 16,
			poiNumber: 17
		},
		{
			name: "The Red Doors",
			type: "point",
			coords: [-73.979947, 40.575775],
			zoom: 13,
			index: 17,
			poiNumber: 18
		},
		{
			name: "Gargiulo’s",
			type: "point",
			coords: [-73.982425, 40.576618],
			zoom: 13,
			index: 18,
			poiNumber: 19
		},
		{
			name: "Randazzo’s Clam Bar",
			type: "point",
			coords: [-73.947617, 40.583941],
			zoom: 13,
			index: 19,
			poiNumber: 20
		},
		{
			name: "Coney’s Cones",
			type: "point",
			coords: [-73.978886, 40.573437],
			zoom: 13,
			index: 20,
			poiNumber: 21
		},
		{
			name: "Steeplechase Beer Garden",
			type: "point",
			coords: [-73.983782, 40.575018],
			zoom: 13,
			index: 21,
			poiNumber: 22
		},
		{
			name: "One Elleven",
			type: "point",
			coords: [-73.952452, 40.577105],
			zoom: 13,
			index: 22,
			poiNumber: 23
		},
		{
			name: "PEP Bakehouse and Coffee Shop",
			type: "point",
			coords: [-73.971624, 40.576057],
			zoom: 13,
			index: 23,
			poiNumber: 24
		},
		{
			name: "Dacha",
			type: "point",
			coords: [-73.979911, 40.576932],
			zoom: 13,
			index: 24,
			poiNumber: 25
		},
		{
			name: "S&A Pizzeria",
			type: "point",
			coords: [-73.994063, 40.575415],
			zoom: 13,
			index: 25,
			poiNumber: 26
		}
	],
	recreation: [
		{
			name: "Luna Park",
			type: "point",
			coords: [-73.978447, 40.57425],
			zoom: 13,
			index: 26,
			poiNumber: 1
		},
		{
			name: "Kaiser Park",
			type: "point",
			coords: [-73.996215, 40.57885],
			zoom: 13,
			index: 27,
			poiNumber: 2
		},
		{
			name: "MatchPoint NYC",
			type: "point",
			coords: [-73.974027, 40.58458],
			zoom: 13,
			index: 28,
			poiNumber: 3
		},
		{
			name: "Sunset View Park",
			type: "point",
			coords: [-74.012355, 40.578801],
			zoom: 13,
			index: 29,
			poiNumber: 4
		},
		{
			name: "Maimonides Park",
			type: "point",
			coords: [-73.985997, 40.575658],
			zoom: 13,
			index: 30,
			poiNumber: 5
		},
		{
			name: "Coney Island Beach",
			type: "point",
			coords: [-73.979857, 40.572799],
			zoom: 13,
			index: 31,
			poiNumber: 6
		},
		{
			name: "Brighton Beach",
			type: "point",
			coords: [-73.965681, 40.57469],
			zoom: 13,
			index: 32,
			poiNumber: 7
		},
		{
			name: "Manhattan Beach Park",
			type: "point",
			coords: [-73.943318, 40.577314],
			zoom: 13,
			index: 33,
			poiNumber: 8
		},
		{
			name: "CartevFitZone",
			type: "point",
			coords: [-73.965085, 40.577233],
			zoom: 13,
			index: 34,
			poiNumber: 9
		},
		{
			name: "Blink Fitness Coney Island",
			type: "point",
			coords: [-73.976114, 40.578223],
			zoom: 13,
			index: 35,
			poiNumber: 10
		},
		{
			name: "Abe Stark Sports Center",
			type: "point",
			coords: [-73.985982, 40.572887],
			zoom: 13,
			index: 36,
			poiNumber: 11
		}
	],
	transportation: [
		{
			name: "Surf Av/W 16 St Bus Stop",
			type: "point",
			coords: [-73.981887, 40.575489],
			zoom: 13,
			index: 37,
			poiNumber: 1
		},
		{
			name: "Ocean Parkway Station",
			type: "point",
			coords: [-73.968144, 40.576422],
			zoom: 13,
			index: 38,
			poiNumber: 2
		},
		{
			name: "Mermaid Av/W 15 St Bus Stop",
			type: "point",
			coords: [-73.983059, 40.576905],
			zoom: 13,
			index: 39,
			poiNumber: 3
		},
		{
			name: "Coney Island-Stillwell Av Station",
			type: "point",
			coords: [-73.981214, 40.577058],
			zoom: 13,
			index: 40,
			poiNumber: 4
		},
		{
			name: "Neptune Av Station",
			type: "point",
			coords: [-73.975784, 40.580332],
			zoom: 13,
			index: 41,
			poiNumber: 5
		},
		{
			name: "W 8 St-NY Aquarium Station",
			type: "point",
			coords: [-73.976689, 40.575854],
			zoom: 13,
			index: 42,
			poiNumber: 6
		},
		{
			name: "Zipcar",
			type: "point",
			coords: [-73.958106, 40.575835],
			zoom: 13,
			index: 43,
			poiNumber: 7
		},
		{
			name: "Surf Ave/Stillwell Av Bus Stop",
			type: "point",
			coords: [-73.981434, 40.575507],
			zoom: 13,
			index: 44,
			poiNumber: 8
		}
	],
	entertainment: [
		{
			name: "Coney Island Amphitheater",
			type: "point",
			coords: [-73.988206, 40.572516],
			zoom: 13,
			index: 45,
			poiNumber: 1
		},
		{
			name: "Deno’s Wonder Wheel Amusement Park",
			type: "point",
			coords: [-73.979293, 40.57414],
			zoom: 13,
			index: 46,
			poiNumber: 2
		},
		{
			name: "The Cyclone Roller Coaster",
			type: "point",
			coords: [-73.977654, 40.574966],
			zoom: 13,
			index: 47,
			poiNumber: 3
		},
		{
			name: "Coney Art Walls",
			type: "point",
			coords: [-73.981705, 40.574216],
			zoom: 13,
			index: 48,
			poiNumber: 4
		},
		{
			name: "Coney Island Beach & Boardwalk",
			type: "point",
			coords: [-73.97908, 40.573172],
			zoom: 13,
			index: 49,
			poiNumber: 5
		},
		{
			name: "B&B Carousell",
			type: "point",
			coords: [-73.983148, 40.572964],
			zoom: 13,
			index: 50,
			poiNumber: 6
		},
		{
			name: "Oceana Theater",
			type: "point",
			coords: [-73.958316, 40.578644],
			zoom: 13,
			index: 51,
			poiNumber: 7
		},
		{
			name: "Coney Island Museum",
			type: "point",
			coords: [-73.979859, 40.575289],
			zoom: 13,
			index: 52,
			poiNumber: 8
		},
		{
			name: "New York Aquarium",
			type: "point",
			coords: [-73.975794, 40.574159],
			zoom: 13,
			index: 53,
			poiNumber: 9
		},
		{
			name: "Regal UA Sheepshead Bay",
			type: "point",
			coords: [-73.930083, 40.585872],
			zoom: 13,
			index: 54,
			poiNumber: 10
		}
	],
	services: [
		{
			name: "Rite Aid Pharmacy",
			type: "point",
			coords: [-73.939572, 40.590433],
			zoom: 13,
			index: 55,
			poiNumber: 1
		},
		{
			name: "Golden Apple Day Spa & Skin Care",
			type: "point",
			coords: [-73.96725, 40.576946],
			zoom: 13,
			index: 56,
			poiNumber: 2
		},
		{
			name: "M&T Bank ATM",
			type: "point",
			coords: [-73.984956, 40.578733],
			zoom: 13,
			index: 57,
			poiNumber: 3
		},
		{
			name: "Walgreens",
			type: "point",
			coords: [-73.961109, 40.59315],
			zoom: 13,
			index: 58,
			poiNumber: 4
		},
		{
			name: "Stop & Shop",
			type: "point",
			coords: [-73.984965, 40.582784],
			zoom: 13,
			index: 59,
			poiNumber: 5
		},
		{
			name: "CVS Pharmacy",
			type: "point",
			coords: [-73.973684, 40.579152],
			zoom: 13,
			index: 60,
			poiNumber: 6
		},
		{
			name: "Brighton Express Food Market",
			type: "point",
			coords: [-73.963517, 40.577415],
			zoom: 13,
			index: 61,
			poiNumber: 7
		},
		{
			name: "Surf Deli & Grocery",
			type: "point",
			coords: [-73.988823, 40.574012],
			zoom: 13,
			index: 62,
			poiNumber: 8
		},
		{
			name: "Mobil",
			type: "point",
			coords: [-73.985494, 40.578689],
			zoom: 13,
			index: 63,
			poiNumber: 9
		},
		{
			name: "Friscia Pharmacy",
			type: "point",
			coords: [-73.982854, 40.576987],
			zoom: 13,
			index: 64,
			poiNumber: 10
		},
		{
			name: "Bank of America",
			type: "point",
			coords: [-73.986719, 40.583302],
			zoom: 13,
			index: 65,
			poiNumber: 11
		},
		{
			name: "Coney Island Pharmacy",
			type: "point",
			coords: [-73.993939, 40.575429],
			zoom: 13,
			index: 66,
			poiNumber: 12
		},
		{
			name: "Chase Bank",
			type: "point",
			coords: [-73.982445, 40.576737],
			zoom: 13,
			index: 67,
			poiNumber: 13
		},
		{
			name: "Shell",
			type: "point",
			coords: [-73.985841, 40.5803],
			zoom: 13,
			index: 68,
			poiNumber: 14
		}
	],
	shopping: [
		{
			name: "Fashion Evidence",
			type: "point",
			coords: [-73.96685, 40.576958],
			zoom: 13,
			index: 69,
			poiNumber: 1
		},
		{
			name: "Astroland Furniture/ Home Decor",
			type: "point",
			coords: [-73.978615, 40.575707],
			zoom: 13,
			index: 70,
			poiNumber: 2
		},
		{
			name: "Bellissi Furniture Gallery",
			type: "point",
			coords: [-73.973682, 40.591992],
			zoom: 13,
			index: 71,
			poiNumber: 3
		},
		{
			name: "Macy’s",
			type: "point",
			coords: [-73.947081, 40.5843],
			zoom: 13,
			index: 72,
			poiNumber: 4
		},
		{
			name: "Kings Plaza Shopping Center",
			type: "point",
			coords: [-73.918186, 40.607494],
			zoom: 13,
			index: 73,
			poiNumber: 5
		},
		{
			name: "Brooklyn Beach Shop",
			type: "point",
			coords: [-73.978113, 40.575665],
			zoom: 13,
			index: 74,
			poiNumber: 6
		},
		{
			name: "Target",
			type: "point",
			coords: [-74.001265, 40.593725],
			zoom: 13,
			index: 75,
			poiNumber: 7
		},
		{
			name: "Williams Candy",
			type: "point",
			coords: [-73.98191, 40.57534],
			zoom: 13,
			index: 76,
			poiNumber: 8
		},
		{
			name: "Ceasar’s Bay Shopping Center",
			type: "point",
			coords: [-73.999802, 40.594427],
			zoom: 13,
			index: 77,
			poiNumber: 9
		}
	]
}
