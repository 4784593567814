import TSVGPropTypes from "@customTypes/SVGPropTypes"

function TelescopeIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<ellipse
				cx="49.94"
				cy="55.88"
				rx="6.68"
				ry="6.76"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="19.07"
				y1="90.53"
				x2="44.76"
				y2="60.15"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="49.94"
				y1="90.68"
				x2="49.94"
				y2="62.64"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="80.81"
				y1="90.53"
				x2="55.31"
				y2="60.15"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="49.94"
				y1="38.52"
				x2="49.94"
				y2="49.12"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<polygon
				points="95.02 29.45 7.08 47.1 4.98 38.52 89.73 9.32 95.02 29.45"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="74.21"
				y1="14.66"
				x2="78.79"
				y2="32.71"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default TelescopeIcon
