import TSVGPropTypes from "@customTypes/SVGPropTypes"

function StarIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<path
				d="m53.47,9.38l10.97,22.08c.25.56.64,1.05,1.14,1.41.5.36,1.08.59,1.69.66l24.21,3.58c.7.09,1.36.38,1.91.83.54.45.95,1.05,1.16,1.72.22.67.24,1.39.06,2.07-.18.68-.55,1.3-1.06,1.78l-17.45,17.27c-.45.42-.78.93-.97,1.51-.19.58-.24,1.19-.13,1.79l4.21,24.28c.12.7.04,1.42-.22,2.07-.27.66-.71,1.22-1.29,1.64-.57.42-1.26.66-1.96.71-.71.05-1.42-.1-2.04-.43l-21.79-11.49c-.56-.27-1.17-.42-1.79-.42s-1.24.14-1.79.42l-21.79,11.49c-.63.33-1.33.48-2.04.43-.71-.05-1.39-.3-1.96-.71-.57-.42-1.02-.98-1.29-1.64s-.34-1.37-.22-2.07l4.21-24.56c.11-.6.06-1.21-.13-1.79-.19-.58-.53-1.1-.97-1.51L6.44,43.5c-.52-.49-.89-1.13-1.06-1.83-.17-.7-.13-1.43.11-2.11.24-.68.67-1.27,1.25-1.71.57-.44,1.26-.7,1.98-.75l24.21-3.58c.61-.07,1.19-.29,1.69-.66.5-.36.89-.85,1.14-1.41l10.97-22.08c.3-.64.78-1.19,1.37-1.57.6-.38,1.29-.58,2.01-.58s1.41.2,2.01.58c.6.38,1.08.93,1.37,1.57Z"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default StarIcon
