import { useState, useEffect } from "react"
import styled from "styled-components"
import { useRanger } from "react-ranger"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function PriceRange() {
	const isIpad = useStore(s => s.isIpad)
  const priceRange = useStore((s) => s.priceRange)
  const minPrice = useStore((s) => s.minPrice)
  const maxPrice = useStore((s) => s.maxPrice)
  const [price, setPrice] = useState(priceRange)
	const setLocalState = useStore(s => s.setLocalState)

	const { getTrackProps: priceTrack, handles: priceHandles } = useRanger({
		values: price,
		onDrag: setPrice,
		onChange: () => {
      setLocalState("priceRange", [price[0], price[1]])
    },
		min: minPrice,
		max: maxPrice,
		stepSize: 1
	})

	useEffect(() => {
		setPrice(priceRange)
	}, [priceRange])

	return (
		<Wrapper>
			<Labels
				style={{
					justifyContent: isIpad ? "space-between" : "center",
					fontSize: isIpad ? "1rem" : "1.3rem",
					padding: "2rem 0"
				}}>
				<Min>{price[0]}k</Min>
				{!isIpad && "-"}
				<Max>{price[1]}k</Max>
			</Labels>
			<Range>
				<div
					{...priceTrack({
						style: {
							position: "absolute",
							top: "50%",
							left: "10%",
							height: "0.1rem",
							width: "80%",
							background: globalStyles.colors.secondary,
							borderRadius: "2px"
						}
					})}>
					{priceHandles.map(({ getHandleProps }) => (
						<div
							{...getHandleProps({
								style: {
									width: "1.5rem",
									height: "1.5rem",
									cursor: "pointer",
									borderRadius: "100%",
									position: "absolute",
									pointerEvents: "all"
								}
							})}
						/>
					))}
				</div>
			</Range>
		</Wrapper>
	)
}

export default PriceRange

const Wrapper = styled.div`
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Range = styled.div`
	position: relative;
	flex: 1;
	width: 100%;
	height: 10%;
	font-size: 1rem;

	& div {
		:nth-of-type(1) {
			background-color: ${globalStyles.colors.secondary};
		}
		:nth-of-type(2) {
			background-color: ${globalStyles.colors.secondary};
		}
	}
`

const Labels = styled.div`
	flex: 1;
	width: 85%;
	height: 10%;
	display: flex;
	align-items: center;
	gap: 3rem;
`

const Min = styled.p``

const Max = styled.p``
