import { useState, useEffect } from "react"
import styled from "styled-components"

import AmenityCopy from "@components/AmenityCopy"
import TransitionDiv from "@components/TransitionDiv"

import { useStore } from "@state/store"
import amenitiesConfig from "@data/amenitiesConfig"

const base = {
	level: "",
	index: "",
	meshID: "",
	name: "",
	position: "",
	copy: ""
}

function AmenityInfo() {
	const [copyID, setCopyID] = useState(0)
	const currentAmenityIndex = useStore(s => s.currentAmenityIndex)
	const dataArr = Object.values(amenitiesConfig)
		.flat()
		.filter(obj => obj.name)

	const copyArr = [base, ...dataArr].map(obj => <AmenityCopy data={obj} />)

	useEffect(() => {
		if (currentAmenityIndex === null) {
			setCopyID(0)
		} else {
			setCopyID(currentAmenityIndex + 1)
		}
	}, [currentAmenityIndex])
	return (
		<AmenityInfoWrapper>
			<TransitionDiv
				childrenArr={copyArr}
				triggerIndex={copyID}
				durationMS={600}
			/>
		</AmenityInfoWrapper>
	)
}

export default AmenityInfo

const AmenityInfoWrapper = styled.div`
	position: absolute;
	width: clamp(500px, 60%, 800px);
	height: clamp(300px, 30%, 500px);
	bottom: 0;
`
