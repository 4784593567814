import globalStyles from "@data/globalStyles"
import styled from "styled-components"
import { toggleArrayItem } from "@utils/functions"
import { device } from "@utils/device"

function BalconyFilter({ balcony, setBalcony }) {
	return (
		<Group>
			<Button
				style={{
					color: balcony 
						? globalStyles.colors.secondary
						: globalStyles.colors.gray
				}}
				onClick={() => {
					setBalcony(balcony ? null : true)
				}}>
				WITH
			</Button>
			<Button
				style={{
					color: balcony === false
						? globalStyles.colors.secondary
						: globalStyles.colors.gray
				}}
				onClick={() => {
					setBalcony(balcony === false ? null : false)
				}}>
				WITHOUT
			</Button>
		</Group>
	)
}

export default BalconyFilter

const Group = styled.div`
	width: 90%;
	display: flex;
	flex-direction: row;
	gap: 2rem;

	@media ${device.pad} {
		gap: 0rem;
	}
`

const Button = styled.div`
	display: flex;
	justify-content: center;
	font-family: title;
	cursor: pointer;
	pointer-events: all;
	transition: all 0.5s ease-out;
`
