import NavCircle from "@components/NavCircle"
import styled from "styled-components"

import routes from "@routes/routes"
import { useLocation, useNavigate } from "react-router-dom"
import { useRef } from "react"
import globalStyles from "@data/globalStyles"

function Surf() {
	const navigate = useNavigate()
	const location = useLocation()
	const slidesRef = useRef(null)

	const state = location.state

	return (
		<Wrapper>
			{/* {routes.map((item, i) => {
				return (
					item.onSurf && (
						<NavCircle
							key={i}
							onClick={() => navigate(item.path)}
							posX={`${item.posX}%`}
							text={item.surfName}
							posY={`${item.posY}%`}
						/>
					)
				)
			})} */}
			{state?.from === "/" && <SlidesWrapper ref={slidesRef} />}
		</Wrapper>
	)
}

export default Surf

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const SlidesWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.main};
	animation-name: fadeOut;
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
	animation-delay: 0.3s;
`
