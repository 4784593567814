import { ReactNode } from 'react';

type TSVGCircleProps = {
  radius: number;
  bgcolor?: string;
  bordercolor?: string;
  borderwidth?: number;
  children?: ReactNode;
  [x: string]: unknown;
};

function SVGCircle(props: TSVGCircleProps) {
  const { radius, bgcolor, bordercolor, borderwidth, children } = props;
  return (
    <svg
      x="0"
      y="0"
      viewBox={`0 0 ${radius * 2.2} ${radius * 2.2}`}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <circle
        r={radius}
        cx={radius * 1.1}
        cy={radius * 1.1}
        fill={bgcolor ?? 'white'}
        stroke={bordercolor ?? 'white'}
        strokeWidth={borderwidth ?? 1}
      />
      {children}
    </svg>
  );
}

export default SVGCircle;
