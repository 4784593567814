import * as THREE from "three"
import shaderMaterial from "@lib/dreiShaderMaterial"
import { extend} from "@react-three/fiber"
import { forwardRef} from "react"

//@ts-ignore
import vertex from "./glsl/vertex.glsl"
//@ts-ignore
import fragment from "./glsl/fragment.glsl"

const BakedShader = shaderMaterial(
	{
		uBlend: { value: 0 },
		uOpacity: { value: 1 },
		uMap: { value: new THREE.Texture() },
		uEnv: { value: new THREE.Texture() }
	},
	vertex,
	fragment,
	() => null
)

extend({ BakedShader })

type BProps = {
	map: THREE.Texture
	uOpacity?: number
}

const BakeShaderMaterial = forwardRef(({ map, uOpacity }: BProps, ref) => {
	return (
		// @ts-ignore
		<bakedShader
			transparent={true}
			side={THREE.DoubleSide}
			flatShading={true}
			uMap={map}
			wireframe={false}
			uOpacity={uOpacity}
			ref={ref}
		/>
	)
})

export default BakeShaderMaterial