import { Suspense, useEffect } from "react"
import styled from "styled-components"

import CircleText from "@components/SVGIcons/CircleText"
import AnimatedIntroText from "@components/AnimatedIntroText"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import { useNavigate } from "react-router-dom"

function Splash() {
	const navigating = useStore(s => s.navigating)
	const setIntroSlide = useStore(s => s.setIntroSlide)
	const introSlide = useStore(s => s.introSlide)
	const sceneLoaded = useStore(s => s.sceneLoaded)
	const navigate = useNavigate()

	useEffect(() => {
		return () => {
			setIntroSlide(0)
		}
	}, [])
	const currentPath = location.pathname
	return (
		<Suspense fallback={null}>
			<Wrapper>
				<AnimatedIntroText />
				<JumpIn
					style={{
						opacity: introSlide === 0 && !navigating && sceneLoaded ? 1 : 0,
						transform:
							introSlide === 0 && !navigating && sceneLoaded
								? "scale(1)"
								: "scale(2)",
						pointerEvents: sceneLoaded ? "auto" : "none"
					}}
					onClick={() => {
						setIntroSlide(introSlide === 1 ? 0 : 1)
						setTimeout(() => {
							navigate("/surf", { state: { from: currentPath } })
						}, 1500)
					}}>
					<CircleText
						text={"-JUMP IN -JUMP IN -JUMP IN -JUMP IN "}
						animated={!navigating}
						className="text"
					/>
				</JumpIn>

				<JumpIn
					style={{
						opacity: !sceneLoaded && !navigating ? 1 : 0,
						transform: !sceneLoaded && !navigating ? "scale(1)" : "scale(0)",
						pointerEvents: "none"
					}}>
					<CircleText
						text={"-LOADING -LOADING -LOADING -LOADING "}
						animated={!navigating}
						className="text"
					/>
				</JumpIn>
			</Wrapper>
		</Suspense>
	)
}

export default Splash

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.main};
`

const JumpIn = styled.div`
	position: absolute;
	z-index: 2;
	bottom: 0;
	right: 0;
	width: 15rem;
	max-width: 15vw;
	/* padding: 1rem; */
	aspect-ratio: 1/1;
	transition-property: opacity, transform;
	transition-duration: 1.5s;
	transition-timing-function: ease-in-out;
	pointer-events: auto;
	cursor: pointer;

	& text {
		font-size: 9rem;
		font-family: title;
		fill: ${globalStyles.colors.secondary};
	}
`
