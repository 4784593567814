import { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"
import globalStyles from "@data/globalStyles"
import PriceRange from "@components/Filter/components/Ranges/PriceRange"
import { useStore } from "@state/store"
import BedsFilter from "./components/BedsFilter"
import BalconyFilter from "./components/BalconyFilter"
import LevelRange from "@components/Filter/components/Ranges/LevelRange"
import AspectFilter from "./components/AspectFilter"
import { device } from "@utils/device"

function Filter({isInt}) {
	const offlineUnits = useStore(s => s.offlineUnits)
	const setShowList = useStore(s => s.setShowList)
	const setFilterOpen = useStore(s => s.setFilterOpen)
	const filterOpen = useStore(s => s.filterOpen)
	const setFilteredUnits = useStore(s => s.setFilteredUnits)
	const fullUnits = useStore(s => s.fullUnits)
	const showList = useStore(s => s.showList)
	const levelRange = useStore(s => s.levelRange)
	const priceRange = useStore(s => s.priceRange)
	const balcony = useStore(s => s.balcony)
	const setBalcony = useStore(s => s.setBalcony)
	const setLocalState = useStore(s => s.setLocalState)
	const filterRef = useRef<HTMLDivElement>()
	const [beds, setBeds] = useState<number[]>([])
	const [aspects, setAspects] = useState<string[]>([])
	const startTime = useRef(0)
	const totalTime = useRef(0)
	const timeThreshold = 1000

	function animate() {
		gsap.to(filterRef.current, {
			opacity: filterOpen ? 1 : 0,
			duration: 1
		})
	}

	function handleFilterBtnClick(e: any) {
		e.stopPropagation()
		animate()
		setFilterOpen(!filterOpen)
		setShowList(false)
	}

	function handleFilterBtnLongClick(e: any) {
		e.stopPropagation()
		e.preventDefault()
		setLocalState("offlineUnits", !offlineUnits)
	}

	function handlePointerDown(e) {
		startTime.current = e.timeStamp
	}

	function handlePointerUp(e) {
		totalTime.current = e.timeStamp - startTime.current
		if (totalTime.current < timeThreshold) {
			handleFilterBtnClick(e)
		} else {
			handleFilterBtnLongClick(e)
		}
		startTime.current = 0
		totalTime.current = 0
	}

	useEffect(() => {
		if (fullUnits) {
			let filtered = []
			const splitAspects = aspects.map(txt => txt.split(" ")).flat()
			filtered = fullUnits.filter(obj => {
				return (
					obj.floor >= levelRange[0] &&
					obj.floor <= levelRange[1] &&
					obj.price >= priceRange[0] &&
					obj.price <= priceRange[1] &&
					(!splitAspects.length ||
						splitAspects.some((item: any) => obj.aspect.includes(item))) &&
					(!beds.length || beds.includes(obj.beds)) &&
					(balcony === null || balcony === obj.balcony) &&
					obj.available
				)
			})
			setFilteredUnits(filtered)
		}
	}, [fullUnits, beds, levelRange, priceRange, aspects, balcony])

	return (
		<FilterWrapper>
			{filterOpen && (
				<FiltersWrapper ref={filterRef}>
					<FilterCategory>
						<FilterLabel>BEDS</FilterLabel>
						<BedsFilter beds={beds} setBeds={setBeds} />
					</FilterCategory>

					<FilterCategory>
						<FilterLabel>LEVEL</FilterLabel>
						<LevelRange />
					</FilterCategory>

					<FilterCategory>
						<FilterLabel>PRICE</FilterLabel>
						<PriceRange />
					</FilterCategory>

					<FilterCategory>
						<FilterLabel>ASPECT</FilterLabel>
						<AspectFilter aspects={aspects} setAspects={setAspects} />
					</FilterCategory>

					<FilterCategory>
						<FilterLabel>BALCONY</FilterLabel>
						<BalconyFilter balcony={balcony} setBalcony={setBalcony} />
					</FilterCategory>
				</FiltersWrapper>
			)}

			<FilterButtons isint={isInt}>
				<Key>
					<span style={{ marginRight: "0.5rem" }}>Available</span>
					<div
						style={{
							width: "1.5rem",
							height: "1.5rem",
							backgroundColor: globalStyles.colors.secondary,
							marginRight: "1rem"
						}}></div>
					<span style={{ marginRight: "0.5rem" }}>Selected</span>
					<div
						style={{
							width: "1.5rem",
							height: "1.5rem",
							backgroundColor: globalStyles.colors.white
						}}></div>
				</Key>
				<FilterButton
					onPointerDown={handlePointerDown}
					onPointerUp={handlePointerUp}
					filterOpen={filterOpen}>
					FILTER/
				</FilterButton>
				<ListButton
					showList={showList}
					onClick={() => {
						setShowList(!showList)
						setFilterOpen(false)
					}}>
					LIST
				</ListButton>
			</FilterButtons>
		</FilterWrapper>
	)
}

export default Filter

const FilterWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	pointer-events: auto;
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	pointer-events: none;

	& svg {
		display: block;
		margin-left: 5rem;
		max-height: 100%;
		& text {
			font-size: 8rem;
			font-family: title;
			transform-box: fill-box;
			transform-origin: bottom left;
			transition: fill 0.6s ease-in-out;
		}
	}
`

const FiltersWrapper = styled.div`
	max-height: 100%;
	width: 29rem;
	height: 38rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 2.8rem;
	/* margin-left: 1.75rem; */
	padding-left: 1.88rem;
	font-size: 1.37888rem;
	font-family: title;
	color: ${globalStyles.colors.secondary};
	gap: 0.5rem;
	animation: fadeIn 0.6s ease-in-out;

	@media ${device.pad} {
		height: 30rem;
		gap: 0rem;
		margin-left: 0.8rem;
	}
`

const FilterCategory = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`

const FilterLabel = styled.p`
	color: ${globalStyles.colors.gray};
	width: 8.18rem;
`

const Key = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		font-size: 1.37888rem;
		font-family: title;
		color: ${globalStyles.colors.gray};
	}
`

const FilterButtons = styled.div<{isint: boolean}>`
	margin-left: 1.175rem;
	margin-top: calc(0.5rem + 35px);
	position: absolute;
	${p => (p.isint ? "top: 3%;" : "bottom: 1%;")}
	pointer-events: all;

	p {
		display: inline;
		color: #ebae15;
		font-family: title;
		font-size: 3.125rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.78125rem;

		:hover {
			opacity: 1;
			color: #ebae15;
		}
	}

	@media ${device.pad} {
		p {
			font-size: 2.5rem;
		}
	}
`

// prettier-ignore
const FilterButton = styled.p<{ filterOpen: boolean }>`
	opacity: ${p => (p.filterOpen ? 1 : 0.5)};
`

// prettier-ignore
const ListButton = styled.p<{ showList: boolean }>`
	opacity: ${p => (p.showList ? 1 : 0.5)};
`
