import { useEffect, useRef } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import Filter from "@components/Filter"
import ApartmentCard from "@components/ApartmentCard"
import Popup from "@components/Popup"
import CloseIcon from "@components/SVGIcons/CloseIcon"
import ApartmentInfo from "@components/ApartmentInfo"
import TableList from "@components/TableList"
import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function Residences() {
	const cardOpen = useStore(s => s.cardOpen)
	const setCardSectionIndex = useStore(s => s.setCardSectionIndex)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const toggleGridView = useStore(s => s.toggleGridView)
	const setShowList = useStore(s => s.setShowList)
	const setFilterOpen = useStore(s => s.setFilterOpen)
	const isInt = useStore(s => s.isInt)
	const navigate = useNavigate()
	const setLocalState = useStore(s => s.setLocalState)

	useEffect(() => {
		toggleGridView(false)
		setShowList(false)
		setFilterOpen(false)
		setCurrentApt(null)

		document.addEventListener("contextmenu", function (event) {
			event.preventDefault()
		})

		return () => {
			setLocalState("cardOpen", false)
			document.oncontextmenu = null
		}
	}, [])

	return (
		<>
			<Wrapper>
				{!isInt && (
					<ButtonWrapper>
						<AmenitiesButton
							onClick={() => {
								setLocalState("navigating", true)
								navigate("/amenities")
							}}>
							<p>SEE THE AMENITIES{" >>>"}</p>
						</AmenitiesButton>
					</ButtonWrapper>
				)}

				<Popup
					openState={cardOpen}
					setOpenState={x => setLocalState("cardOpen", x)}
					durationMS={800}
					closeButton={
						<CloseIcon
							strokeWidth={5}
							strokeColor={globalStyles.colors.main}
							style={{ pointerEvents: "auto" }}
						/>
					}
					onClose={() => {
						setCardSectionIndex(0)
						setShowList(false)
						setFilterOpen(false)
					}}>
					<ApartmentCard />
				</Popup>
				<ApartmentInfo />
				<Filter isInt={isInt} />
				<TableList />
			</Wrapper>
		</>
	)
}

export default Residences

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	animation: fadeIn 0.8s ease 300ms forwards;
	opacity: 0;
`

const ButtonWrapper = styled.div`
	position: absolute;
	right: 1%;
	bottom: 2%;
`
const AmenitiesButton = styled.div`
	position: relative;
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	pointer-events: all;
	color: ${globalStyles.colors.white};
	font-family: title;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.25rem;
`
