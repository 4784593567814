import styled from "styled-components"

import SphericalTour from "@components/SphericalTour"

import { useStore } from "@state/store"
import { organizeAspects } from "@utils/functions"
import globalStyles from "@data/globalStyles"
import { Label } from "@components/Plan3DViewer/Plan3DViewer"

function ApartmentView({isInt}) {
	const currentApt = useStore(s => s.currentApt)
	const views = currentApt ? organizeAspects([...currentApt["aspect"]]) : []
	const maxFloor = 25
	const totalViews = 7
	const height = currentApt
		? Math.round((totalViews / maxFloor) * currentApt.floor)
		: 5

	const textures = {
		1: "/images/views/13m.webp",
		2: "/images/views/28m.webp",
		3: "/images/views/38m.webp",
		4: "/images/views/50m.webp",
		5: "/images/views/62m.webp",
		6: "/images/views/70m.webp",
		7: "/images/views/82m.webp"
	}

	const rotation = {
		N: -1.25,
		E: 0.1,
		S: 1.8,
		W: 3.2
	}

	const limit = {
		1: -0.7,
		2: -1.9,
		3: -3.5,
		4: -5.1
	}

	return (
		currentApt && (
			<AptViewWrapper>
				<SphericalTour
					texture={textures[height]}
					hasLimit
					initialPosition={[0, rotation[views[0]], 0]}
					// minHor={-Infinity}
					// maxHor={Infinity}
					minHor={limit[views.length]}
					maxHor={0}
					minVer={1.57}
					maxVer={1.5}
					camera={{
						fov: 58
					}}
				/>
				<Label isint={isInt}>
					<h1>THE</h1>
					<h1>VIEWS</h1>
				</Label>
			</AptViewWrapper>
		)
	)
}

export default ApartmentView

const AptViewWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`
