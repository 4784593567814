import globalStyles from "@data/globalStyles"
import { useGLTF } from "@react-three/drei"
import { forwardRef } from "react"

type TBlockProps = {
	data: { meshID: string, position: number[] },
	opacity: number,
	color?: string
	[x:string]: any
}

const Block = forwardRef(function Block(
	{ data, opacity, color, ...props }: TBlockProps,
	ref: any
) {
	const { meshID } = data
	//@ts-ignore
	const { nodes: block } = useGLTF(`models/amenities_new/${meshID}.glb`)
	return (
		<group>
			<mesh
				renderOrder={-10}
				ref={ref}
				geometry={block.mesh_0.geometry}
				position={[data.position[0], data.position[1], data.position[2]]}
				{...props}>
				<meshMatcapMaterial
					flatShading={true}
					opacity={opacity}
					color={color ?? globalStyles.colors.white}
					transparent
				/>
			</mesh>
		</group>
	)
})

export default Block
