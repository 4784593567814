import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import TextFrame from "@components/TextFrame/TextFrame"

import globalStyles from "@data/globalStyles"
import { randomNumberInRange } from "@utils/functions"

function Loading({ cue }) {
	const [show, setShow] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(0)
	const intervalRef = useRef(null)

	const images = [
		"url(/images/lifestyle/wheel.webp)",
		"url(/images/lifestyle/fair.webp)",
		"url(/images/lifestyle/flag.webp)",
		"url(/images/lifestyle/hole.webp)"
	]

	const nextIndex = useRef(
		Math.round(randomNumberInRange(0, images.length - 1))
	)
	function animate() {
		gsap.fromTo(
			"#left",
			{
				attr: {
					dx: 0
				}
			},
			{
				attr: {
					dx: randomNumberInRange(1, 2)
				},
				duration: 0.3
			}
		)
		gsap.fromTo(
			"#right",
			{
				attr: {
					dx: 0
				}
			},
			{
				attr: {
					dx: randomNumberInRange(1, 2) * -1
				},
				duration: 0.3,
				repeat: 3,
				onComplete: () => {
					nextIndex.current =
						nextIndex.current === images.length - 1 ? 0 : nextIndex.current + 1
					setCurrentIndex(nextIndex.current)
					gsap.to("#left", {
						attr: { dx: 0 },
						duration: 0.25,
						delay: 0.25
					})
					gsap.to("#right", {
						attr: { dx: 0 },
						duration: 0.25,
						delay: 0.25
					})
					gsap.to("#image", {
						backgroundImage: images[nextIndex.current],
						duration: 0.8
					})
				}
			}
		)
	}
	useEffect(() => {
		if (cue) {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [cue])

	useEffect(() => {
		if (intervalRef.current === null) {
			intervalRef.current = setTimeout(() => {
				cue && animate()
				intervalRef.current = null
			}, 1100)
		}
		return () => {
			clearTimeout(intervalRef.current)
			intervalRef.current = null
		}
	}, [currentIndex, cue])

	return (
		show && (
			<LoadingWrapper
				style={{
					animationName: cue ? "fadeIn" : "fadeOut",
					animationDuration: "500ms"
				}}
				onClick={() => {
					// set(!x)
					animate()
				}}>
				<svg>
					<defs>
						<filter id="anaglyph3d">
							<feOffset
								in="SourceGraphic"
								dx="0"
								dy="0"
								result="left"
								id="left"
							/>
							<feOffset
								in="SourceGraphic"
								dx="0"
								dy="0"
								result="right"
								id="right"
							/>
							<feComponentTransfer in="left" result="leftRed">
								<feFuncR type="identity"></feFuncR>
								<feFuncG type="discrete" tableValues="0"></feFuncG>
								<feFuncB type="discrete" tableValues="0"></feFuncB>
							</feComponentTransfer>

							<feComponentTransfer in="right" result="rightCyan">
								<feFuncR type="discrete" tableValues="0"></feFuncR>
								<feFuncG type="identity"></feFuncG>
								<feFuncB type="identity"></feFuncB>
							</feComponentTransfer>
							<feBlend in="leftRed" in2="rightCyan" mode="screen" />
						</filter>
					</defs>
				</svg>

				<Content className="centerABS">
					<TextFrame
						topText="coney"
						rightText="fifteen"
						bottomText="surf&"
						leftText="island"
						className="centerABS">
						<Animation
							id="image"
							className="centerREL"
							style={{
								backgroundImage: images[nextIndex.current]
							}}
						/>
					</TextFrame>
				</Content>
			</LoadingWrapper>
		)
	)
}

export default Loading

const LoadingWrapper = styled.div`
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.gray};
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
`

const Content = styled.div`
	width: 20%;
	height: 35%;
	max-width: 300px;
	max-height: 400px;
`

const Animation = styled.div`
	width: 80%;
	height: 90%;
	filter: url(#anaglyph3d);
	transition: background-image 0.5s ease-in-out;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`
