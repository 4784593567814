const textPaths = [
	{
		type: "path",
		coord:
			"m815.39,600.74v-12.38l-33.6,10.17c-3.65-6.52-10.83-10.17-20.67-10.17-10.94,0-21.77,6.52-21.77,22.1v18.68h76.03v-11.6h-30.72c0-2.21-.11-4.64,0-7.63l30.72-9.17Zm-42.11,16.8h-22.77v-7.07c0-9.17,7.63-10.61,10.83-10.61,3.54,0,11.94.67,11.94,11.27v6.41Z"
	},
	{
		type: "polygon",
		coord:
			"782.9 513.21 771.63 513.21 771.63 537.64 750.85 537.64 750.85 509.12 739.36 509.12 739.36 549.9 815.39 549.9 815.39 537.64 782.9 537.64 782.9 513.21"
	},
	{
		type: "path",
		coord:
			"m739.36,706.39h55.04c13.04,0,22.44-6.52,22.44-20.34s-9.28-20.34-22.44-20.34h-55.04v11.83h55.04c3.21,0,10.83.22,10.83,8.51s-7.63,8.51-10.83,8.51h-55.04v11.83Z"
	},
	{
		type: "path",
		coord:
			"m787.54,772.7v11.16h6.85c10.28,0,22.44-3.98,22.44-20.34,0-13.7-8.73-20.44-21.77-20.44-15.47,0-20.22,9.62-24.09,19.34-3.21,8.29-7.29,10.28-10.94,10.28-2.32,0-10.94.55-10.94-9.17,0-8.29,7.96-9.17,11.27-9.17h2.65v-11.27h-2.65c-10.39,0-22.44,3.98-22.44,20.44,0,13.81,8.07,20.34,21.33,20.34,14.03,0,17.35-6.85,23.21-20,3.54-8.29,9.5-9.5,12.6-9.5,2.65,0,10.5.88,10.5,9.17s-7.52,9.17-11.16,9.17h-6.85Z"
	},

	{
		type: "path",
		coord:
			"m696.63,833.42c13.7,0,19.01,14.26,19.01,26.53,0,22.11-13.48,26.53-21.11,26.53s-19.67-3.76-19.67-22.44h11.16c0,7.07,3.32,11.27,8.51,11.27,4.42,0,9.17-2.65,9.17-15.36s-4.42-15.36-9.17-15.36c-3.43,0-5.64,1.44-6.96,3.1-.77.88-1.44,1.88-1.88,3.09h-10.83v-41.78h38.68v10.83h-27.52v17.35c1.99-1.99,4.42-3.76,10.61-3.76"
	},
	{
		type: "path",
		coord:
			"m645.82,873.43h-14.26v-64.44h-12.16c-.11,2.1-.77,4.09-1.77,5.75-1.77,2.98-5.31,5.75-12.49,5.75v8.84h14.26v44.1h-14.26v11.61h40.67v-11.61Z"
	},
	{
		type: "path",
		coord:
			"m550.69,833.42c-6.19,0-8.62,1.77-10.61,3.76v-17.35h27.52v-10.83h-38.68v41.78h10.83c.44-1.22,1.11-2.21,1.88-3.09,1.33-1.66,3.54-3.1,6.96-3.1,4.75,0,9.17,2.98,9.17,15.36s-4.75,15.36-9.17,15.36c-5.2,0-8.51-4.2-8.51-11.27h-11.16c0,18.68,12.38,22.44,19.67,22.44s21.11-4.42,21.11-26.53c0-12.27-5.31-26.53-19.01-26.53"
	},
	{
		type: "path",
		coord:
			"m499.88,873.43h-14.26v-64.44h-12.16c-.11,2.1-.77,4.09-1.77,5.75-1.77,2.98-5.31,5.75-12.49,5.75v8.84h14.26v44.1h-14.26v11.61h40.67v-11.61Z"
	},

	{
		type: "path",
		coord:
			"m236.22,526.11v11.6h3.43c3.21,0,11.05.11,11.05,8.84s-7.85,8.84-11.05,8.84h-34.04c-3.21,0-11.05-.11-11.05-8.84s7.85-8.84,11.05-8.84h3.32v-11.6h-3.32c-10.39,0-22.44,3.98-22.44,20.44,0,13.7,9.28,20.34,22.44,20.34h34.04c10.28,0,22.44-3.98,22.44-20.34,0-13.7-9.4-20.44-22.44-20.44h-3.43Z"
	},
	{
		type: "path",
		coord:
			"m239.64,448.86h-34.04c-12.82,0-22.44,6.74-22.44,20.45s9.28,20.34,22.44,20.34h34.04c13.04,0,22.44-6.52,22.44-20.34s-9.4-20.45-22.44-20.45Zm0,29.29h-34.04c-3.21,0-11.05-.22-11.05-8.84s7.85-8.84,11.05-8.84h34.04c3.21,0,11.05.11,11.05,8.84s-7.85,8.84-11.05,8.84Z"
	},
	{
		type: "polygon",
		coord:
			"260.64 371.51 184.61 371.51 184.61 383 229.15 383 184.61 400.68 184.61 412.29 260.64 412.29 260.64 400.68 216.88 400.68 260.64 383 260.64 371.51"
	},
	{
		type: "polygon",
		coord:
			"260.64 294.03 249.04 294.03 249.04 322.66 228.37 322.66 228.37 297.46 216.88 297.46 216.88 322.66 196.1 322.66 196.1 294.03 184.61 294.03 184.61 334.81 260.64 334.81 260.64 294.03"
	},
	{
		type: "polygon",
		coord:
			"184.61 228.83 211.46 237.12 184.61 245.41 184.61 257.45 228.37 243.09 260.64 243.09 260.64 230.93 228.37 230.93 184.61 216.78 184.61 228.83"
	},

	{
		type: "polygon",
		coord:
			"312.84 179.84 312.84 126.13 326.77 126.13 326.77 114.97 286.65 114.97 286.65 126.13 300.58 126.13 300.58 179.84 286.21 179.84 286.21 191 326.99 191 326.99 179.84 312.84 179.84"
	},
	{
		type: "path",
		coord:
			"m374.62,163.15h-11.16v6.85c0,10.28,3.98,22.44,20.34,22.44,13.7,0,20.45-8.73,20.45-21.77,0-15.47-9.62-20.23-19.34-24.09-8.29-3.21-10.28-7.3-10.28-10.94,0-2.32-.55-10.94,9.17-10.94,8.29,0,9.17,7.96,9.17,11.27v2.65h11.27v-2.65c0-10.39-3.98-22.44-20.45-22.44-13.82,0-20.34,8.07-20.34,21.33,0,14.04,6.85,17.35,20,23.21,8.29,3.54,9.5,9.5,9.5,12.6,0,2.65-.88,10.5-9.17,10.5s-9.17-7.51-9.17-11.16v-6.85Z"
	},
	{
		type: "polygon",
		coord:
			"440.82 191 481.6 191 481.6 178.84 452.98 178.84 452.98 114.97 440.82 114.97 440.82 191"
	},
	{
		type: "path",
		coord:
			"m548.13,114.97h-19.01l-11.94,76.03h11.6l2.21-14.92h15.25l2.21,14.92h11.6l-11.93-76.03Zm-15.36,50.29l5.86-39.12,5.86,39.12h-11.71Z"
	},
	{
		type: "polygon",
		coord:
			"636.32 191 636.32 114.97 624.82 114.97 624.82 159.5 607.14 114.97 595.54 114.97 595.54 191 607.14 191 607.14 147.24 624.82 191 636.32 191"
	},
	{
		type: "path",
		coord:
			"m691.69,114.97h-18.68v76.03h18.68c15.58.33,22.1-10.28,22.1-21.33v-33.27c0-11.38-6.52-21.44-22.1-21.44Zm10.72,54.37c0,3.09-1.22,10.5-10.5,10.5h-7.63v-53.71h7.63c9.28,0,10.5,7.4,10.5,10.61v32.6Z"
	}
]

export default textPaths
