const globalStyles = {
  colors: {
    main: "#0000FF",
    secondary: "#EBAE15",
    accent: "#24408E",
    white: "#FFFFFF",
    black: "#000000",
    gray: "#C9CACC"

  },
}

export default globalStyles

