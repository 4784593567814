import { useState, useEffect, useRef, Fragment } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"

import amenitiesConfig from "@data/amenitiesConfig"
import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function AmenitiesMenu() {
	const [state, set] = useState(false)
	const data = Object.values(amenitiesConfig)
	const setCurrentAmenityIndex = useStore(s => s.setCurrentAmenityIndex)
	const currentAmenityIndex = useStore(s => s.currentAmenityIndex)
	const activeLevel = useStore(s => s.activeLevel)
	const setActiveLevel = useStore(s => s.setActiveLevel)
	const amenityModelVisible = useStore(s => s.amenityModelVisible)
	const setAmenityModelVisible = useStore(s => s.setAmenityModelVisible)
	const setAmenitySwitching = useStore(s => s.setAmenitySwitching)

	const gsapConfig = {
		stagger: 0.15,
		duration: 1.5
	}

	const navigate = useNavigate()

	function buttonsTween(dir) {
		gsap.to(".btn", {
			x: dir ? "0%" : "150%",
			...gsapConfig,
			delay: 1
		})
		!state && set(true)
	}

	function handleAmenityClick(data) {
		if (amenityModelVisible) {
			if (data.index === currentAmenityIndex) {
				setAmenitySwitching(true)
				setTimeout(() => {
					setActiveLevel(null)
					setCurrentAmenityIndex(null)
					setAmenityModelVisible(false)
					setTimeout(() => {
						setAmenitySwitching(false)
					}, 500)
				}, 1000)
			}
			if (data.level === activeLevel) {
				setCurrentAmenityIndex(data.index)
			} else {
				setAmenitySwitching(true)
				setTimeout(() => {
					setActiveLevel(data.level)
					setCurrentAmenityIndex(data.index)
					setTimeout(() => {
						setAmenitySwitching(false)
					}, 500)
				}, 1000)
			}
			return
		} else {
			setActiveLevel(data.level)
			setCurrentAmenityIndex(data.index)
			setAmenityModelVisible(true)
		}
	}

	useEffect(() => {
		!state && buttonsTween(true)
	}, [state])

	return (
		<Fragment>
			<AmenitiesMenuWrapper>
				{data.map((amnArr, arrIndex) => {
					return amnArr.map((obj, objIndex) => {
						return (
							obj.name && (
								<Box
									key={objIndex}
									className="btn"
									style={{
										opacity: !state
											? 0
											: activeLevel === obj.level ||
											  (activeLevel === null && state)
											? 1
											: 0.1,
										color:
											currentAmenityIndex === obj.index
												? globalStyles.colors.main
												: globalStyles.colors.black
									}}
									onClick={() => {
										handleAmenityClick(obj)
									}}>
									<p>{obj.name}</p>
								</Box>
							)
						)
					})
				})}
			</AmenitiesMenuWrapper>
			<Box
				className="back"
				onClick={() => {
					if (amenityModelVisible) {
						setAmenitySwitching(true)
						setTimeout(() => {
							setActiveLevel(null)
							setCurrentAmenityIndex(null)
							setAmenityModelVisible(false)
							setTimeout(() => {
								setAmenitySwitching(false)
							}, 500)
						}, 1000)
					} else {
						navigate("/residences")
					}
				}}>
				<span />
				<p style={{ opacity: amenityModelVisible ? 0 : 1 }}>
					{"<<< "}BACK TO RESIDENCES
				</p>
				<p style={{ opacity: amenityModelVisible ? 1 : 0 }}>
					{"<<< "}BACK TO AMENITIES
				</p>
			</Box>
		</Fragment>
	)
}

export default AmenitiesMenu

const AmenitiesMenuWrapper = styled.div`
	position: absolute;
	top: 10%;
	right: 1%;
	height: 80%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	pointer-events: none;

	.btn {
		transform: translateX(150%);
		transition: opacity 1s ease-in-out;
		opacity: 0;
	}

	.zoom {
		color: ${globalStyles.colors.white};
		position: absolute;
		transition: opacity 0.8s ease-in-out;
	}
`

const Box = styled.div`
	position: relative;
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	pointer-events: all;
	cursor: pointer;
	transition-property: opacity, color;
	transition-duration: 0.8s;
	transition-timing-function: ease-in-out;

	font-family: title;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.25rem;
	text-transform: uppercase;

	:has(span) {
		position: absolute;
		color: ${globalStyles.colors.main};
		font-size: 1.5rem;
		width: 100%;
		white-space: nowrap;
		bottom: -2%;
		right: 1%;

		& p {
			position: absolute;
			transition-property: opacity;
			transition-duration: 0.8s;
			transition-timing-function: ease-in-out;
		}
	}
`
