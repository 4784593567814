import { useEffect, useState, ReactNode, Fragment } from "react"
import styled from "styled-components"

type TProps = {
  childrenArr: ReactNode[]
  triggerIndex: number
  durationMS: number
  [x: string]: any
}

function TransitionDiv({ childrenArr, triggerIndex, durationMS, ...props }: TProps) {
  const [visibleIndex, setVisibleIndex] = useState(0)
  //TODO HANDLE MID-ANIMATION INDEX CHANGE
  useEffect(() => {
    const root = document.getElementById("root") as HTMLElement
    if (visibleIndex === null) {
      setVisibleIndex(triggerIndex)
    } else {
      root.style.pointerEvents = "none"
      setTimeout(() => {
        setVisibleIndex(triggerIndex)
        root.style.pointerEvents = "auto"
      }, durationMS + 100)
    }
  }, [triggerIndex])

  return (
    <Fragment>
      {childrenArr.map((node, i) => {
        return (
          (visibleIndex === i || triggerIndex === i) && (
            <Wrapper
              key={`children${i}`}
              style={{
                ...props.style,
                animationDuration: `${durationMS}ms`,
                animationTimingFunction: "ease-in",
                animationFillMode: "forwards",
                animationName: triggerIndex === i ? "fadeIn" : "fadeOut"
              }}
              {...props}>
              {node}
            </Wrapper>
          )
        )
      })}
    </Fragment>
  )
}

export default TransitionDiv

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`
