import styled from "styled-components"
import { useState, useRef, useEffect } from "react"
import { device } from "@utils/device"
import gsap from "gsap"

import Map from "@components/Map"
import MapMarker from "@components/MapMarker"
import ScrollIcon from "@components/SVGIcons/ScrollIcon"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import { mapData } from "@data/temp/mapData"
import LoadingIcon from "@components/SVGIcons/LoadingIcon"

function Coney() {
	const mapRef = useRef(null)
	const [loaded, setLoaded] = useState(false)
	const [activeCategory, setActiveCategory] = useState<string>("surf")
	const [activePoi, setActivePoi] = useState<number | null>(null)
	const { navigating } = useStore(s => ({
		navigating: s.navigating
	}))

	const centralMarkerData = mapData.surf[0]

	const categories = Object.keys(mapData)
	const poisArr = [
		...mapData.dining,
		...mapData.recreation,
		...mapData.transportation,
		...mapData.entertainment,
		...mapData.services,
		...mapData.shopping
	]

	function getCategory(n) {
		if (n >= 0 && n <= 25) {
			return "dining"
		} else if (n >= 26 && n <= 36) {
			return "recreation"
		} else if (n >= 37 && n <= 44) {
			return "transportation"
		} else if (n >= 45 && n <= 54) {
			return "entertainment"
		} else if (n >= 55 && n <= 68) {
			return "services"
		} else if (n >= 69 && n <= 77) {
			return "shopping"
		}
	}

	function getMarkersArr(callback) {
		const markers = Array.from(document.getElementsByClassName("poiSelector"))
		markers.forEach((marker: HTMLElement) => {
			callback(marker)
		})
	}

	const markerArr = poisArr.map<any>(obj => {
		const category = getCategory(obj.index)
		return {
			component: (
				<MapMarker
					circleradius={5}
					text={obj.poiNumber}
					textcolor={globalStyles.colors.black}
					textsize={50}
					bordercolor={globalStyles.colors.secondary}
					borderwidth={0.5}
					width="50px"
					height="50px"
					className="poiSelector"
					id={`poi-marker-${obj.index}`}
					data-category={category}
				/>
			),
			x: obj.coords[0],
			y: obj.coords[1]
		}
	})

	useEffect(() => {
		if (mapRef.current && !navigating && loaded) {
			const map = mapRef.current.getRef()
			setTimeout(() => {
				map.flyTo(mapCameraSettings.surf)
			}, 1000)
		}
	}, [navigating, loaded])

	useEffect(() => {
		if (!mapRef.current || !loaded) return
		const map = mapRef.current.getRef()
		map.flyTo(mapCameraSettings[activeCategory])
		getMarkersArr(marker => {
			const cat = marker.dataset.category
			gsap.to(marker, {
				opacity: cat === activeCategory ? 1 : 0,
				scale: cat === activeCategory ? markerScale[activeCategory] : 1
			})
		})
	}, [activeCategory])

	useEffect(() => {
		const map = mapRef.current.getRef()
		if (activePoi === null) {
			gsap.to("#surf-marker", {
				scale: 1
			})
			getMarkersArr(marker => {
				const cat = marker.dataset.category
				if (activeCategory !== cat) return
				gsap.to(marker, {
					opacity: 1,
					scale: markerScale[activeCategory]
				})
			})
		} else {
			const data = mapData[activeCategory].filter(
				obj => obj.index === activePoi
			)[0]
			map.flyTo({
				center: data.coords,
				zoom: 14,
				curve: 1.2,
				speed: 0.8
			})
			gsap.to("#surf-marker", {
				scale: 0.5
			})
			getMarkersArr(marker => {
				const id = marker.id
				const cat = marker.dataset.category
				if (activeCategory !== cat) return
				gsap.to(marker, {
					opacity: id === `poi-marker-${activePoi}` ? 1 : 0,
					scale:
						id === `poi-marker-${activePoi}` ? 2 : markerScale[activeCategory]
				})
			})
		}
	}, [activePoi, activeCategory])
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				backgroundColor: globalStyles.colors.main
			}}>
			<Loading
				className="centerABS fadeIn">
				LOADING
				<LoadingIcon color={globalStyles.colors.secondary} />
			</Loading>
			<Wrapper
				style={{
					opacity: loaded ? 1 : 0
				}}>
				<MapContainer
					style={{
						opacity: loaded ? 1 : 0
					}}>
					<Map
						//@ts-ignore
						token={import.meta.env.VITE_MAPBOX_TOKEN}
						//@ts-ignore
						styleURL={import.meta.env.VITE_MAPBOX_STYLE}
						ref={mapRef}
						onloaded={() => {
							setLoaded(true)
						}}
						initialValues={{
							x: -73.91673910983532,
							y: 40.69778378535321,
							zoom: 6.775834549879766
						}}
						centralMarker={{
							component: (
								<MapMarker
									width="100px"
									height="100px"
									id="surf-marker"
									circleradius={10}
									text={["1515", "Surf"]}
									bordercolor={globalStyles.colors.secondary}
									borderwidth={1}
								/>
							),
							x: centralMarkerData.coords[0],
							y: centralMarkerData.coords[1]
						}}
						poiMarkersArr={markerArr}
					/>
				</MapContainer>
				<POIPanelWrapper>
					<POIPanel>
						<div
							style={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between"
							}}>
							{categories.map((ctg, i) => {
								return (
									<Category
										$active={activeCategory === ctg}
										key={i}
										onClick={() => {
											setActiveCategory(ctg)
											setActivePoi(null)
										}}>
										{ctg}
									</Category>
								)
							})}
						</div>
						<Right>
							<List
								style={{
									opacity: activeCategory === "surf" ? 0 : 1
								}}>
								{mapData[activeCategory].map((poi, index) => {
									return (
										<POI
											key={`poiMarker${index}`}
											onClick={() => {
												setActivePoi(poi.index)
											}}
											$active={activePoi === poi.index}>
											{`${poi.name} ${poi.poiNumber ?? ""}`}
										</POI>
									)
								})}
							</List>
							<ScrollPrompt
								style={{
									opacity: activeCategory !== "surf" ? 1 : 0
								}}>
								<ScrollIcon
									strokeColor={globalStyles.colors.secondary}
									strokeWidth={3}
								/>
							</ScrollPrompt>
						</Right>
					</POIPanel>
				</POIPanelWrapper>
			</Wrapper>
		</div>
	)
}

export default Coney

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.main};
	pointer-events: auto;
	opacity: 0;
	transition-property: opacity;
	transition-duration: 1.6s;
	transition-timing-function: ease-in-out;

	#surf-marker,
	.poiSelector {
		filter: drop-shadow(5px 5px 8px ${globalStyles.colors.black}44);
		& text tspan {
			font-family: title;
		}
	}

	.poiSelector {
		opacity: 0;
	}
`

const MapContainer = styled.div`
	position: relative;
	width: 100%;
	height: 70%;
	overflow: hidden;
	transition: opacity 1s ease-in-out;
`

const POIPanelWrapper = styled.div`
	position: absolute;
	bottom: 0;
	height: 30%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

const POIPanel = styled.div`
	width: calc(100% - 4rem);
	height: 90%;
	border-bottom: 2px solid ${globalStyles.colors.secondary};
	padding-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
`

//prettier-ignore
const Category = styled.h1<{ $active: boolean }>`
	font-family: titleLight;
	font-size: clamp(20px, 20px, 15px);
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	transition-property: color;
	transition-duration: 0.6s;
	transition-timing-function: ease-in-out;
	color: ${p => (p.$active ? globalStyles.colors.secondary : globalStyles.colors.white)};
	padding-left: 1rem;
`

const CategoryToggle = styled.p`
	font-size: 2.7225rem;
	cursor: pointer;
	margin-top: 1rem;
	transition: fadeIn 0.6s ease-in-out;

	@media ${device.laptop} {
		margin-top: 0.25rem;
		font-size: 2.25rem;
	}
`

const Right = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	height: 100%;

	@media ${device.pad} {
		gap: 0rem;
	}
`

const List = styled.ul`
	overflow-y: scroll;
	transition-property: opacity;
	transition-duration: 0.5s;
	transition-timing-function: ease-in-out;
	padding-top: 2rem;
	touch-action: pan-y;

	> * {
		touch-action: pan-y;
	}
	::-webkit-scrollbar {
		display: none;
	}
`

type TPOI = {
	$active: boolean
}

//prettier-ignore
const POI =styled.li<TPOI>` 
	flex: 1;
	cursor: pointer;
	pointer-events: auto;
	list-style: none;
	font-family: title;
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 121%;
	letter-spacing: 0.05625rem;
	text-transform: uppercase;
	text-align: right;
	transition: 0.3 easInOut;
	opacity: ${p => (p.$active ? 1 : 0.7)};
	color: ${p => (p.$active ? globalStyles.colors.secondary : globalStyles.colors.white)};
	transition-property: opacity, color;
	transition-duration: .5s;
	transition-timing-function: ease-in-out;

	:hover {
		color: ${globalStyles.colors.secondary};
		opacity: 1;
	}

	@media ${device.laptop}{
		font-size: 1.675rem;
	}

	@media ${device.pad}{
		font-size: 1.575rem;
	}
`

const Loading = styled.div`
	font-size: 3rem;
	color: ${globalStyles.colors.white};
	font-family: title;
	display: flex;
	gap: 2rem;
	align-items: center;
`

const ScrollPrompt = styled.div`
	width: 3.625rem;
	height: 8.8125rem;
	margin: 0 0rem 0.8rem 0;
	align-self: flex-end;
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.5s;
	transition-timing-function: ease-in-out;

	@media ${device.tablet} {
		margin: 0 0rem 0.6rem 0;
		width: 3.625rem;
		height: 6.8125rem;
	}
	@media ${device.pad} {
		margin: 0 0rem 0.6rem 0;
		width: 3.625rem;
		height: 6.8125rem;
	}
`

const mapCameraSettings = {
	surf: {
		center: [-73.97218459542138, 40.584170287779955],
		zoom: 13.149174339281851,
		curve: 1,
		speed: 1
	},
	dining: {
		center: [-73.97022416674268, 40.58400560347246],
		zoom: 13.722523185179803,
		curve: 1,
		speed: 1
	},
	recreation: {
		center: [-73.9801629446384, 40.58194071384827],
		zoom: 13.529100730409196,
		curve: 1,
		speed: 1
	},
	transportation: {
		center: [-73.9706106182725, 40.579460711248174],
		zoom: 14.689635459032816,
		curve: 1,
		speed: 1
	},
	entertainment: {
		center: [-73.96069251710756, 40.58077173345686],
		zoom: 13.81923441256512,
		curve: 1,
		speed: 1
	},
	services: {
		center: [-73.96750895242724, 40.58136315924068],
		zoom: 13.826152930978964,
		curve: 1,
		speed: 1
	},
	shopping: {
		center: [-73.97342156260858, 40.58548622474606],
		zoom: 13.819234412565097,
		curve: 1,
		speed: 1
	}
}

const markerScale = {
	base: 1,
	dining: 1.2,
	recreation: 1,
	transportation: 1,
	entertainment: 1,
	services: 1,
	shopping: 1
}
