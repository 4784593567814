import styled from "styled-components"
import { useStore } from "@state/store"
import AmenityInfo from "@components/AmenityInfo"
import globalStyles from "@data/globalStyles"
import { useNavigate } from "react-router-dom"
import { device } from "@utils/device"
import { useEffect, useState } from "react"
import AmenitiesMenu from "@components/AmenitiesMenu"

function Amenities() {
	const setCurrentAmenityIndex = useStore(s => s.setCurrentAmenityIndex)
	const setAmenityModelVisible = useStore(s => s.setAmenityModelVisible)
	const setActiveLevel = useStore(s => s.setActiveLevel)

	useEffect(() => {
		return () => {
			setAmenityModelVisible(false)
			setActiveLevel(null)
			setCurrentAmenityIndex(null)
		}
	}, [])

	return (
		<Wrapper>
			<AmenitiesMenu />
			<AmenityInfo />
		</Wrapper>
	)
}

export default Amenities

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	animation: fadeIn 0.8s ease 300ms forwards;
	opacity: 0;
`

const ViewButton = styled.div`
	display: flex;
	text-transform: uppercase;
	text-align: left;
	align-items: center;
	margin-top: 30%;
	pointer-events: auto;
	padding: 0 0 0 1.75rem;
	width: fit-content;

	h1 {
		cursor: pointer;
		color: ${globalStyles.colors.main};
		opacity: 0.5;
		font-family: titleLight;
		font-size: 4rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 1.571rem;

		:hover {
			opacity: 1;
			transition: 0.2s ease-in;
		}
	}

	@media ${device.laptop} {
		padding: 0 0 0 1rem;
		h1 {
			font-size: 3rem;
		}
	}

	@media ${device.tablet} {
		h1 {
			font-size: 3rem;
		}
	}

	@media ${device.pad} {
		h1 {
			font-size: 3rem;
		}
	}

	@media ${device.mobileL} {
		h1 {
			font-size: 2rem;
		}
	}
`
