import { forwardRef, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { useLoader } from "@react-three/fiber"
import * as THREE from "three"

import BakeShaderMaterial from "@canvas_components/BakedShaderMaterial/BakedShaderMaterial"

import amenitiesConfig from "@data/amenitiesConfig"
import { useStore } from "@state/store"

type TAmenityModelProps = {
	data: { meshID: string, position: number[] },
	opacity: number,
	[x: string]: any
}

const paths = [
	"/models/amenities-full/beach-deck.glb",
	"/models/amenities-full/coworking-lounge.glb",
	"/models/amenities-full/east-avenue-deck.glb",
	"/models/amenities-full/gallery-lounge.glb",
	"/models/amenities-full/game-room.glb",
	"/models/amenities-full/leasing-lounge.glb",
	"/models/amenities-full/level-1-rest.glb",
	"/models/amenities-full/level-2-rest.glb",
	"/models/amenities-full/level-3-rest.glb",
	"/models/amenities-full/level-8-rest.glb",
	"/models/amenities-full/living-room.glb",
	"/models/amenities-full/lobby.glb",
	"/models/amenities-full/media-lounge.glb",
	"/models/amenities-full/ocean-deck.glb",
	"/models/amenities-full/ocean-lounge.glb",
	"/models/amenities-full/sky-deck.glb",
	"/models/amenities-full/sky-deck.glb",
	"/models/amenities-full/sky-lounge.glb",
	"/models/amenities-full/the-court.glb",
	"/models/amenities-full/the-gym.glb",
	"/models/amenities-full/the-library.glb",
	"/models/amenities-full/the-pool.glb",
	"/models/amenities-full/the-studio.glb",
	"/models/amenities-full/the-sun-deck.glb"
]

useGLTF.preload(paths)

const AmenityModel = forwardRef(function AmenityModel(
	{ data, opacity, ...props }: TAmenityModelProps,
	ref: any
) {
	const { meshID } = data
	//@ts-ignore
	const { nodes: model } = useGLTF(`models/amenities-full/${meshID}.glb`)
	const map = useLoader(
		THREE.TextureLoader,
		`textures/${TEXTURE_INDEX[meshID]}.jpg`
	)
	map.flipY = false
	const materialRef = useRef<THREE.MeshBasicMaterial>()
	return (
		<mesh
			renderOrder={-10}
			ref={ref}
			geometry={model.mesh_0.geometry}
			position={[data.position[0], data.position[1], data.position[2]]}
			{...props}>
			<BakeShaderMaterial ref={materialRef} map={map} uOpacity={opacity}/>
			
		</mesh>
	)
})

export default AmenityModel

const TEXTURE_INDEX = {
	"level-1-rest": "Level_1",
	"leasing-lounge": "Level_1",
	"east-avenue-deck": "Level_1",
	lobby: "Level_1",
	"level-2-rest": "Level_2",
	"coworking-lounge": "Level_2",
	"gallery-lounge": "Level_2",
	"living-room": "Level_3",
	"beach-deck": "Garden",
	"game-room": "Level_3",
	"level-3-rest": "Level_3",
	"media-lounge": "Level_4",
	"the-library": "Level_5",
	"the-court": "Level_6",
	"the-studio": "Level_7",
	"the-gym": "Level_7",
	"level-8-rest": "Level_8",
	"ocean-deck": "Level_8",
	"ocean-lounge": "Level_8",
	"the-pool": "Level_8",
	"the-sun-deck": "Level_9",
	"sky-deck": "Level_25",
	"sky-lounge": "Level_25"
}
