import { Fragment, useEffect, useRef, useState } from "react"
import gsap from "gsap"
import CustomEase from "gsap/CustomEase"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import textPaths from "@data/splashSVG"

gsap.registerPlugin(CustomEase)
CustomEase.create("custom", "0.175, 0.885, 0.32, 1.275")

function SlideOne() {
	const [visibleImage, setVisibleImage] = useState(0)
	const navigating = useStore(s => s.navigating)
	const introSlide = useStore(s => s.introSlide)
	const imageRef = useRef<SVGGElement>()
	const textRef = useRef<SVGGElement>()
	const textPathsRef = useRef<(SVGPathElement | SVGPolygonElement)[]>([])
	const imagesRef = useRef<SVGImageElement[]>([])
	const imgIndexRef = useRef(0)
	const images: string[] = [
		"/images/intro/jump.webp",
		"/images/intro/bubbles.webp",
		"/images/intro/frame.webp",
		"/images/intro/train.webp"
	]

	const setImagesLoaded = useStore(s => s.setImagesLoaded)

	useEffect(() => {
		const loadImage = (url: string): Promise<boolean> => {
			return new Promise<boolean>((resolve, reject) => {
				const img = new Image()
				img.onload = () => resolve(true)
				img.onerror = () => reject(false)
				img.src = url
			})
		}

		const areImagesLoaded = async (urls: string[]): Promise<boolean> => {
			try {
				const imagePromises: Promise<boolean>[] = urls.map(url =>
					loadImage(url)
				)
				await Promise.all(imagePromises)
				return true
			} catch (error) {
				return false
			}
		}

		const checkImages = async () => {
			try {
				const result = await areImagesLoaded(images)
				setImagesLoaded(result)
			} catch (error) {
				console.error("Error checking image loading status:", error)
				setImagesLoaded(false)
			}
		}

		checkImages()
	}, [])
	
	const slideConfig = {
		duration: 1.5,
		ease: "custom"
	}
	const scaleIn = "scale(1)"
	const scaleOut = "scale(0.8)"
	const scaleConfig = {
		duration: 0.8,
		ease: "ease",
		stagger: 0.05
	}
	const textTimeline = gsap.timeline({
		delay: 0.5,
		repeat: -1,
		paused: true
	})

	function handleImgIndexChange() {
		setVisibleImage(
			imgIndexRef.current === images.length - 1 ? 0 : imgIndexRef.current + 1
		)
	}

	useEffect(() => {
		if (textPathsRef.current && !navigating) {
			textTimeline.to(textPathsRef.current, {
				attr: {
					transform: scaleIn,
					opacity: 1,
					...scaleConfig
				},
				stagger: scaleConfig.stagger
			})
			textTimeline.add(() => {}, "+=2")
			textTimeline.to(textPathsRef.current, {
				attr: {
					transform: scaleOut,
					opacity: 0,
					...scaleConfig
				},
				onStart: () => handleImgIndexChange(),
				stagger: scaleConfig.stagger
			})
		}
	}, [navigating])

	useEffect(() => {
		imgIndexRef.current = visibleImage
		if (imageRef.current && !navigating) {
			imagesRef.current.forEach((elm, i) => {
				if (elm) {
					gsap.to(elm, {
						attr: {
							transform: imgIndexRef.current === i ? scaleIn : scaleOut,
							opacity: imgIndexRef.current === i ? 1 : 0
						},
						duration: 2,
						ease: "custom"
					})
				}
			})
		}
	}, [visibleImage, navigating])

	useEffect(() => {
		if (textRef.current && imageRef.current) {
			gsap.to(textRef.current, {
				attr: {
					opacity: introSlide === 0 ? 1 : 0
				},
				...slideConfig
			})
			gsap.to(imageRef.current, {
				attr: {
					opacity: introSlide === 0 ? 1 : 0
				},
				...slideConfig
			})
		}
	}, [introSlide])

	useEffect(() => {
		if (introSlide === 0) {
			textTimeline.restart()
		} else {
			textTimeline.pause()
		}
	}, [introSlide, navigating])

	return (
		<Fragment>
			<g ref={imageRef}>
				{images.map((src, i) => {
					return (
						<image
							key={`img${i}`}
							width="350"
							height="480"
							x={325}
							y={260}
							xlinkHref={src}
							transform={scaleOut}
							opacity={0}
							preserveAspectRatio="xMidYMid slice"
							ref={node => {
								if (node) {
									imagesRef.current[i] = node
								}
							}}
						/>
					)
				})}
			</g>
			<g ref={textRef}>
				<g>
					{textPaths.map((obj, i) => {
						return obj.type === "path" ? (
							<path
								key={i}
								d={obj.coord}
								ref={node => {
									if (node) {
										textPathsRef.current[i] = node
									}
								}}
								fill={globalStyles.colors.secondary}
								opacity={0}
								transform={scaleOut}
							/>
						) : (
							<polygon
								key={i}
								points={obj.coord}
								ref={node => {
									if (node) {
										textPathsRef.current[i] = node
									}
								}}
								fill={globalStyles.colors.secondary}
								opacity={0}
								transform={scaleOut}
							/>
						)
					})}
				</g>
			</g>
		</Fragment>
	)
}

export default SlideOne
