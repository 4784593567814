import TSVGPropTypes from "@customTypes/SVGPropTypes"

function ShareIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<circle
				cx="25.35"
				cy="50"
				r="12.83"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}  
			/>
			<circle
				cx="74.68"
				cy="17.49"
				r="12.83"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<circle
				cx="74.68"
				cy="82.58"
				r="12.83"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="63.97"
				y1="24.55"
				x2="35.68"
				y2="43.19"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="63.97"
				y1="75.52"
				x2="35.68"
				y2="56.83"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default ShareIcon
