import { useRef, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"
import { Html } from "@react-three/drei"
import gsap from "gsap"
import { useLocation } from "react-router-dom"

import globalStyles from "@data/globalStyles"

function ConeyLabel({ htmlLabel, onClick, polePosition, ...props }) {
	const labelRef = useRef<THREE.Mesh>()
	const poleRef = useRef<THREE.Mesh>(null)
	const htmlRef = useRef<HTMLDivElement>()
	const { pathname } = useLocation()
	useFrame((state, delta) => {
		const camera = state.camera
		labelRef.current.lookAt(camera.position)
	})

	useEffect(() => {
		if (poleRef.current) {
			gsap.to(htmlRef.current, {
				duration: 1,
				opacity: pathname === "/surf" ? 1 : 0,
				pointerEvents: pathname === "/surf" ? "auto" : "none",
				ease: "power2.out"
			})
			gsap.to(poleRef.current.material, {
				duration: 1,
				opacity: pathname === "/surf" ? 1 : 0,
				pointerEvents: pathname === "/surf" ? "auto" : "none",
				ease: "power2.out"
			})
		}
	}, [pathname])

	return (
		<group>
			<mesh ref={labelRef} {...props}>
				<Html occlude distanceFactor={3} ref={htmlRef}>
					<div
						className="centerABS"
						style={{
							opacity: pathname === "/surf" ? 1 : 0,
							transition: "opacity 1s ease"
						}}
						onClick={e => onClick && onClick(e)}>
						{htmlLabel}
					</div>
				</Html>
			</mesh>
			<mesh position={polePosition} ref={poleRef}>
				<cylinderGeometry args={[0.01, 0.01, 0.85, 16]} />
				<meshBasicMaterial
					color={globalStyles.colors.secondary}
					opacity={0}
					transparent
				/>
			</mesh>
		</group>
	)
}

export default ConeyLabel
