import { Fragment, useEffect } from "react"
import styled from "styled-components"

import TelescopeIcon from "@components/SVGIcons/TelescopeIcon"
import Popup from "@components/Popup"
import CloseIcon from "@components/SVGIcons/CloseIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function AmenityCopy({ data }) {
	const amenityRenderOpen = useStore(s => s.amenityRenderOpen)
	const setAmenityRenderOpen = useStore(s => s.setAmenityRenderOpen)
	const currentAmenityIndex = useStore(s => s.currentAmenityIndex)

	useEffect(() => {
		return () => {
			setAmenityRenderOpen(false)
		}
	}, [])

	return (
		<Fragment>
			<AmenityCopyWrapper>
				{data.name !== "" && (
					<Fragment>
						<Header>
									<div>
										<Title>{data.name}</Title>
										{data.level !== "" && <SubTitle>Level {data.level}</SubTitle>}
									</div>
							<Icons>
								{data.src && (
									<Icon
										onClick={() => {
											currentAmenityIndex !== null &&
												setAmenityRenderOpen(!amenityRenderOpen)
										}}>
										<TelescopeIcon
											strokeWidth={3}
											strokeColor={globalStyles.colors.main}
											bgColor="transparent"
										/>
									</Icon>
								)}
							</Icons>
						</Header>
						<Copy>{data.copy}</Copy>
					</Fragment>
				)}
			</AmenityCopyWrapper>
			<Popup
				openState={amenityRenderOpen}
				setOpenState={setAmenityRenderOpen}
				durationMS={800}
				closeButton={
					<div>
						<CloseIcon strokeWidth={5} strokeColor={globalStyles.colors.main} />
					</div>
				}
				onClose={() => {
					setAmenityRenderOpen(false)
				}}>
				<AmenityRender className="centerABS">
					<img className="centerABS" src={data!.src}/>
				</AmenityRender>
			</Popup>
		</Fragment>
	)
}

export default AmenityCopy

const AmenityCopyWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	padding: 1rem;

	.frame {
		font-size: 1.2rem;
		width: clamp(250px, 40%, 400px);

		& div:nth-of-type(3) {
			white-space: nowrap;
			justify-content: flex-start;
		}

		& div:nth-of-type(4) {
			justify-content: flex-end;
			padding-bottom: 4rem;
		}

		& div:nth-of-type(5) {
			margin-top: auto;

			& p {
				color: ${globalStyles.colors.white};
			}
		}
	}
`
const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;
	height: 100%;
	height: auto;
`

const Title = styled.h1`
	color: ${globalStyles.colors.main};
	font-family: titleLight;
	font-size: 4rem;
	font-style: normal;
	line-height: normal;
`

const SubTitle = styled.h3`
	color: ${globalStyles.colors.white};
	font-family: subTitle;
	font-size: 1.8rem;
	font-style: normal;
	line-height: 2rem;
	align-self: flex-end;
	text-align: left;
`

const Icons = styled.div`
	position: relative;
	gap: 10%;
	pointer-events: all;
	height: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 100%;
`

const Icon = styled.div`
	height: 4rem;
	aspect-ratio: 1/1;
`

const Copy = styled.p`
	width: 100%;
	color: ${globalStyles.colors.black};
	font-family: copy;
	font-size: clamp(16px, 1.25rem, 40px);
	padding-bottom: 1rem;
`

const AmenityRender = styled.div`
	width: 100%;
	height: 100%;

	& img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
	}

`
