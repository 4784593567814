import { useRef, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { gsap, Sine } from "gsap"
import * as THREE from "three"

import Block from "./Block"

import amenitiesConfig from "@data/amenitiesConfig"
import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function AmenitiesBlocks() {
	const [buildingInCamera, setBuildingInCamera] = useState(false)
	const navigating = useStore(s => s.navigating)
	const amenityModelVisible = useStore(s => s.amenityModelVisible)
	const currentAmenityIndex = useStore(s => s.currentAmenityIndex)
	const amenitySwitching = useStore(s => s.amenitySwitching)
	const blockRefs = useRef([])
	const data = Object.values(amenitiesConfig)
		.flat()
		.filter(obj => obj.name)
	const { pathname } = useLocation()
	const duration = .8
	const delay = .5
	const activeAmenityColor = new THREE.Color(globalStyles.colors.main)
	const unselectedColor = new THREE.Color(globalStyles.colors.gray)

	useEffect(() => {
		blockRefs.current.forEach(mesh => {
			if (mesh && pathname === "/amenities") {
				gsap.to(mesh.material, {
					opacity: 1,
					duration,
					ease: Sine.easeInOut
				})
				gsap.to(mesh.material.color, {
					r: activeAmenityColor.r,
					g: activeAmenityColor.g,
					b: activeAmenityColor.b,
					duration,
					ease: Sine.easeInOut
				})
			}
		})
	}, [pathname])

	useEffect(() => {
		if (amenityModelVisible && !buildingInCamera) {
			blockRefs.current.forEach(mesh => {
				if (mesh) {
					gsap.to(mesh.material, {
						opacity: 0,
						duration,
						onComplete: () => {
							setBuildingInCamera(true)
							blockRefs.current.forEach(mesh => {
								gsap.to(mesh.material, {
									opacity: 1,
									duration,
									delay,
									ease: Sine.easeInOut
								})
							})
						},
						ease: Sine.easeInOut
					})
				}
			})
		}
		if (!amenityModelVisible && buildingInCamera) {
			blockRefs.current.forEach(mesh => {
				if (mesh) {
					gsap.to(mesh.material, {
						opacity: 0,
						duration,
						onComplete: () => {
							setBuildingInCamera(false)
							blockRefs.current.forEach(mesh => {
								gsap.to(mesh.material, {
									opacity: 1,
									duration,
									delay,
									ease: Sine.easeInOut
								})
							})
						},
						ease: Sine.easeInOut
					})
				}
			})
		}
	}, [amenityModelVisible])

	useEffect(() => {
		if (currentAmenityIndex !== null) {
			blockRefs.current.forEach((mesh, meshIndex) => {
				const { index } = data[meshIndex]
				if (mesh) {
					gsap.to(mesh.material.color, {
						r:
							index === currentAmenityIndex
								? activeAmenityColor.r
								: unselectedColor.r,
						g:
							index === currentAmenityIndex
								? activeAmenityColor.g
								: unselectedColor.g,
						b:
							index === currentAmenityIndex
								? activeAmenityColor.b
								: unselectedColor.b,
						duration,
						ease: Sine.easeInOut
					})
				}
			})
		} else {
			blockRefs.current.forEach((mesh, meshIndex) => {
				if (mesh) {
					gsap.to(mesh.material.color, {
						r: activeAmenityColor.r,
						g: activeAmenityColor.g,
						b: activeAmenityColor.b,
						duration,
						delay,
						ease: Sine.easeInOut
					})
				}
			})
		}
	}, [currentAmenityIndex])

	useEffect(() => {
		return () => {
			blockRefs.current.forEach(mesh => {
				if (mesh) {
					gsap.set(mesh.material, {
						opacity: 0
					})
				}
			})
		}
	}, [])

	return (
		pathname === "/amenities" && (
			<group>
				{data.map((obj, index) => {
					return (
						<Block
							data={{
								meshID: obj.meshID,
								position: [obj.position[0], obj.position[1], -obj.position[2]]
							}}
							key={`amenityBlock-${obj.index}`}
							ref={mesh => {
								if (mesh) {
									blockRefs.current[index] = mesh
								}
							}}
							visible={!navigating}
							opacity={0}
						/>
					)
				})}
			</group>
		)
	)
}

export default AmenitiesBlocks
