const RightArrowIcon = ({ fill: currentColor }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="231"
			height="18"
			viewBox="0 0 231 18"
			fill={currentColor}>
			<path
				d="M229.846 9.77405C230.314 9.30664 230.314 8.54883 229.846 8.08142L222.23 0.464627C221.762 -0.0027771 221.004 -0.0027771 220.537 0.464627C220.069 0.932032 220.069 1.68984 220.537 2.15725L227.307 8.92773L220.537 15.6982C220.069 16.1656 220.069 16.9234 220.537 17.3908C221.004 17.8582 221.762 17.8582 222.23 17.3908L229.846 9.77405ZM0 10.1246H229V7.73087H0V10.1246Z"
				fill={currentColor}
			/>
		</svg>
	)
}

export default RightArrowIcon
