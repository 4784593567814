import { useLayoutEffect, useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"

import * as PLANS from "./Floorplans"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function FloorplanManager({ ...props }) {
	const { currentApt } = useStore(s => ({
		currentApt: s.currentApt
	}))
	const polygonsRef = useRef(null)
	const Plan = currentApt ? PLANS[`Plan${currentApt.type}`] : null
	const timeline = gsap.timeline({ delay: 0.8, ease: "power1.inOut" })
	const duration = 2

	useLayoutEffect(() => {
		if (Plan) {
			const target = Array.from(document.getElementById("walls").children)
			const text = Array.from(document.getElementById("text").children)
			const details = Array.from(document.getElementById("furniture").children)

			if (target) {
				const lengths = target.map((t: any) => t.getTotalLength())

				timeline.set([...target, ...details, ...text], { opacity: 0 }, 0)
				timeline.set(".svgPlan", { opacity: 1 })
				timeline.set(target, { opacity: 1 }, 0)
				target.forEach((t, i) => {
					timeline.set(
						t,
						{
							fill: "transparent",
							stroke: globalStyles.colors.white,
							strokeDashoffset: lengths[i],
							strokeDasharray: lengths[i]
						},
						0
					)
					timeline.to(
						t,
						{
							duration,
							strokeDashoffset: 0
						},
						">"
					)
					timeline.to(
						t,
						{
							duration: duration / 2,
							stroke: globalStyles.colors.main,
							fill: globalStyles.colors.main,
							onComplete: () => {}
						},
						"<80%"
					)
				})
				timeline.to(details, { duration: duration / 3, opacity: 1 })
				timeline.to(
					text,
					{ duration: duration / 3, opacity: 1, stagger: 0.01 },
					"<"
				)
			}
		}
	})

	useEffect(() => {
		return () => (polygonsRef.current = null)
	}, [])
	return (
		<SVG
			x="0"
			y="0"
			width="100%"
			height="100%"
			viewBox="0 0 1000 1000"
			className="centerABS svgPlan">
			{Plan && <Plan />}
		</SVG>
	)
}

export default FloorplanManager

const SVG = styled.svg`
	max-height: 80vh;
	max-width: 80vw;
	opacity: 0;
`
