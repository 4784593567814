import { useRef, useEffect } from "react"
import styled from "styled-components"

import gsap from "gsap"

function CircleText({ text, animated = false, ...props }) {
	const textRef = useRef<SVGTextElement>()

	useEffect(() => {
		if (animated && textRef.current) {
			gsap.fromTo(
				textRef.current,
				{ attr: { transform: "rotate(0 500 500)" } },
				{ attr: { transform: "rotate(360 500 500)" }, duration: 30, repeat: -1, ease: "none" }
			)
		}
	}, [animated])
	return (
		<SVG viewBox="0 0 1000 1000" width="100%" height="100%" {...props}>
			<defs>
				<path
					id="MyPath"
					fill="none"
					stroke="none"
					d="m860.15,500c0,198.9-161.24,360.15-360.15,360.15s-360.15-161.24-360.15-360.15S301.1,139.85,500,139.85s360.15,161.24,360.15,360.15Z"
				/>
			</defs>
			<text ref={textRef}>
				<textPath href="#MyPath">{text}</textPath>
			</text>
		</SVG>
	)
}

export default CircleText

const SVG = styled.svg`
`
