import { useRef, useEffect, useCallback, Fragment } from "react"
import styled from "styled-components"
import gsap from "gsap"

import CircleText from "@components/SVGIcons/CircleText"
import ShareIcon from "@components/SVGIcons/ShareIcon"
import FavCard from "@components/FavCardWrapper/FavCard"
import Popup from "@components/Popup"
import ApartmentCard from "@components/ApartmentCard"
import CloseIcon from "@components/SVGIcons/CloseIcon"
import ScrollIcon from "@components/SVGIcons/ScrollIcon"
import Share from "@components/Share"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import { randomNumberInRange } from "@utils/functions"

function Favorites() {
	const navigating = useStore(s => s.navigating)
	const favorites = useStore(s => s.favorites)
	const cardOpen = useStore(s => s.cardOpen)
	const sharePopup = useStore(s => s.sharePopup)
	const setLocalState = useStore(s => s.setLocalState)
	const animated = useRef<HTMLDivElement[]>([])
	const wrapperRef = useRef<HTMLDivElement>()
	const topRef = useRef(0)
	const timeline = gsap.timeline()
	const label = favorites.length > 0 ? ["LIVE", "OUTSIDE", "THE", "ORDINARY"] : ["YOU", "HAVE NO", "FAVORITES", "YET"]

	const handleRef = useCallback(
		(node, i) => {
			if (node) {
				animated.current[i] = node
			}
		},
		[favorites]
	)
	useEffect(() => {
		topRef.current = 0
		if (animated.current.length >= 0 && !navigating) {
			animated.current.forEach((elm, i) => {
				timeline.to(
					elm,
					{
						top: "25vh",
						rotate: `${randomNumberInRange(-22.5, 22.5)}deg`,
						opacity: 1,
						duration: 0.3,
						delay: randomNumberInRange(0.01, 0.05) * i
					},
					"<"
				)
			})
			timeline.add(() => {}, "+=0.5")
			animated.current.forEach((elm, i) => {
				topRef.current
				timeline.to(
					elm,
					{
						// opacity: 1,
						top: `${topRef.current}vh`,
						left: i % 2 === 0 ? "0vw" : "50vw",
						duration: 0.5 + i / 100,
						ease: "power2.in",
						delay: randomNumberInRange(0.01, 0.05) * i
					},
					"<"
				)
				if (i % 2 !== 0) {
					topRef.current += 50
				}
			})
		}
	}, [navigating])

	// useEffect(() => {
	// 	if (animated.current.length !== favorites.length) {
	// 		const filtered = animated.current.forEach(
	// 			anm => !favorites.includes(anm.id.substring(7))
	// 		)
	// 		animated.current = filtered
	// 	}
	// }, [favorites])

	useEffect(() => {
		return () => {
			setLocalState("cardOpen", false)
		}
	}, [])

	return (
		<Wrapper ref={wrapperRef}>
			<Label>
				{label.map((txt, i) => {
					return (
						<h1
							key={`label${i}`}
							style={{
								animationDelay: `${i * 150}ms`,
								animationName: navigating ? undefined : "fadeIn"
							}}>
							{txt}
						</h1>
					)
				})}
			</Label>
			{favorites.map((unitID, i) => {
				return (
					<CardWrapper
						key={`fav${i}`}
						ref={n => handleRef(n, i)}
						id={`favCard${unitID}`}>
						<FavCard unit={unitID} className="centerREL card" />
					</CardWrapper>
				)
			})}
			{favorites.length > 0 && (
				<Fragment>
					<ScrollPrompt>
						<ScrollIcon
							strokeColor={globalStyles.colors.secondary}
							strokeWidth={3}
						/>
					</ScrollPrompt>
					<SharePrompt
						onClick={() => {
							setLocalState("sharePopup", true)
						}}>
						<CircleText
							text={
								"\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f"
							}
							animated={!navigating}
							className="centerABS text"
						/>
						<ShareIcon
							strokeColor={globalStyles.colors.secondary}
							bgColor="transparent"
							strokeWidth={2}
							className="centerABS"
						/>
					</SharePrompt>
				</Fragment>
			)}

			<Popup
				openState={cardOpen}
				setOpenState={x => setLocalState("cardOpen", x)}
				durationMS={800}
				closeButton={
					<CloseIcon strokeWidth={5} strokeColor={globalStyles.colors.main} />
				}
				onClose={() => {
					setLocalState("currentApt", null)
					setLocalState("cardSectionIndex", 0)
				}}>
				<ApartmentCard />
			</Popup>
			<Popup
				openState={sharePopup}
				setOpenState={x => setLocalState("sharePopup", x)}
				durationMS={800}
				closeButton={
					<CloseIcon strokeWidth={5} strokeColor={globalStyles.colors.white} />
				}>
				<Share />
			</Popup>
		</Wrapper>
	)
}

export default Favorites

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.main};
	white-space: normal;
	overflow: hidden scroll;
	touch-action: pan-y;
	pointer-events: auto;

	* {
		touch-action: pan-y;
		pointer-events: auto;
	}

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${globalStyles.colors.black}66;
		border-radius: 50px;
	}

	::-webkit-scrollbar-track {
		background-color: ${globalStyles.colors.secondary};
	}

	.card {
		max-width: 750px;
		box-shadow: 0.5rem 0.5rem 0.5rem ${globalStyles.colors.black}44;
	}
`

const SharePrompt = styled.div`
	position: fixed;
	bottom: 0;
	right: 1%;
	width: 12rem;
	padding: 1rem;
	aspect-ratio: 1/1;
	border-radius: 100%;
	background: rgba(215, 215, 215, 0.001);
	backdrop-filter: blur(1.2rem);
	cursor: pointer;

	& text {
		font-size: 9rem;
		font-family: title;
		fill: ${globalStyles.colors.secondary};
	}

	& svg {
		:nth-of-type(2) {
			width: 40%;
			transform: translate(-55%, -50%);
		}
	}
`

const ScrollPrompt = styled.div`
	position: fixed;
	top: 3rem;
	right: 1rem;
	height: 12rem;
	width: 6rem;
	padding: 1rem 0 1rem 1rem;
`

const CardWrapper = styled.div`
	position: absolute;
	display: inline-block;
	margin-left: 8%;
	width: 38%;
	height: 50vh;
	opacity: 1;
	top: -50vh;
	left: 25vw;
`

const Label = styled.div`
	position: fixed;
	pointer-events: none;
	width: 100%;
	bottom: 0;
	margin-left: 1.2rem;

	& h1 {
		pointer-events: none;
		text-align: left;
		font-size: 12rem;
		line-height: 12rem;
		letter-spacing: 4rem;
		margin-top: -1.5rem;
		margin-right: -4rem;
		font-family: titleLight;
		color: ${globalStyles.colors.secondary};
		opacity: 0;
		animation-duration: 1.8s;
		animation-fill-mode: forwards;
	}
`
