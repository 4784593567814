import amenitiesConfig from "./amenitiesConfig"

const amenitiesCameraInfo = Object.values(amenitiesConfig)
	.flat()
	.filter(obj => obj.cameraConfig)
	.map(obj => obj.cameraConfig)

export const SceneData = [
	{
		id: "splash",
		title: "Splash",
		page: "/",
		rest: {
			target: [0, 0, 0],
			camera: [-5.994564858075958, 0.6976831814830297, -2.8280573179870045],
			fov: 55
		}
	},
	{
		id: "scene-1",
		title: "Residences",
		page: "/residences",
		rest: {
			target: [0, 0, 0],
			camera: [-2, 0.4, 1.3],
			fov: 55
		},
		aspects: [
			{
				aspect: "S",
				target: [0, 0, 0],
				camera: [-1.7, 0.2, 0.8],
				fov: 55
			},
			{
				aspect: "N",
				target: [0, 0, 0],
				camera: [1.2, 0.4, -0.3],
				fov: 55
			},
			{
				aspect: "E",
				target: [0, 0, 0],
				camera: [-1, -0.1, 1.5],
				fov: 55
			},
			{
				aspect: "W",
				target: [-0.2, 0, 0],
				camera: [-0.8, 0.4, -1.4],
				fov: 55
			}
		]
	},
	{
		id: "scene-2",
		title: "Amenities",
		page: "/amenities",
		rest: {
			target: [0, 0, 0],
			camera: [-1.141500255763719, 0.0774452719204055, 1.5785299462643543],
			fov: 55
		},
		zoomed: amenitiesCameraInfo,
		notZoomed: {
			1: {
				target: [0, 0, 0],
				camera: [0.09657004126327798, 0.0974136522985949, 1.6893688893564282],
				fov: 55
			},
			2: {
				target: [0, 0, 0],
				camera: [0.09657004126327798, 0.2974136522985949, 1.6893688893564282],
				fov: 55
			},
			3: {
				target: [0, 0, 0],
				camera: [0.47648456708456005, 0.417888946193101, 1.7715622756405086],
				fov: 55
			},
			4: {
				target: [0, 0, 0],
				camera: [-0.5583483971029913, 0.3402200671409593, 1.6734688620308022],
				fov: 55
			},
			5: {
				target: [0, 0, 0],
				camera: [-0.5583483971029913, 0.4402200671409593, 1.834688620308022],
				fov: 55
			},
			7: {
				target: [0, 0, 0],
				camera: [0.3189296926458299, 0.6680256048630851, 1.811572039217522],
				fov: 55
			},
			8: {
				target: [0, 0, 0],
				camera: [-1.9737059769904569, 0.2493416702404174, 1.429664812948523],
				fov: 55
			},
			9: {
				target: [0, 0, 0],
				camera: [-1.9737059769904569, 0.2493416702404174, 1.229664812948523],
				fov: 55
			},
			25: {
				target: [0, 0, 0],
				camera: [-0.32221183599652514, 1.0496924868689257, 1.4068912723746723],
				fov: 55
			}
		}
	},
	{
		id: "scene-3",
		title: "Surf",
		page: "/surf",
		rest: {
			target: [0, 0, 0],
			camera: [-4.4, -0.24, -1.4297986630774293],
			fov: 55
		}
	}
]
