import TWEEN from "@tweenjs/tween.js"

const animateCamera = (camera, controls, cameraSettings) => {
	const { position, target, duration, minDistance } = cameraSettings
	controls.enabled = false

	//what about minDistance

	const originalPosition = {
		x: camera.position.x,
		y: camera.position.y,
		z: camera.position.z
	}

	const originalTarget = {
		x: controls.target.x,
		y: controls.target.y,
		z: controls.target.z
	}

	const newPosition = position || originalPosition
	const newTarget = target || originalTarget

	const zoomOutPosition = {
		x: originalPosition.x * 1.1,
		y: originalPosition.y * 1.1,
		z: originalPosition.z * 1.1
	}

	const zoomOutTarget = {
		x: originalTarget.x * 1.1,
		y: originalTarget.y * 1.1,
		z: originalTarget.z * 1.1
	}

	const zoomOutPositionTween = new TWEEN.Tween(camera.position)
		.to(zoomOutPosition, duration ? duration / 2 : 1250)
		.easing(TWEEN.Easing.Quadratic.Out)

	const zoomOutTargetTween = new TWEEN.Tween(controls.target)
		.to(zoomOutTarget, duration ? duration / 2 : 1250)
		.easing(TWEEN.Easing.Quadratic.Out)
		.onStart(() => {
			zoomOutPositionTween.start()
		})

	const positionTween = new TWEEN.Tween(camera.position)
		.to(newPosition, duration || 2500)
		.easing(TWEEN.Easing.Quadratic.InOut)
		.onUpdate(() => {
			controls.update()
		})

	const targetTween = new TWEEN.Tween(controls.target)
		.to(newTarget, duration || 2500)
		.easing(TWEEN.Easing.Quadratic.InOut)
		.onStart(() => {
			zoomOutTargetTween.start()
		})

	zoomOutTargetTween.chain(targetTween)
	targetTween.chain(positionTween)

	positionTween.onComplete(() => {
		controls.enabled = true
	})

	targetTween.start()
}

export default animateCamera
