import { useEffect, useRef, useState } from "react"
import { gsap, Sine } from "gsap"

import AmenityModel from "./AmenityModel"

import { useStore } from "@state/store"
import amenitiesConfig from "@data/amenitiesConfig"
import { useGLTF } from "@lib/useGLTF"

function AmenityModels() {
	const [visible, setVisible] = useState(false)
	const amenityModelVisible = useStore(s => s.amenityModelVisible)
	const activeLevel = useStore(s => s.activeLevel)
	const currentAmenityIndex = useStore(s => s.currentAmenityIndex)
	const amenitySwitching = useStore(s => s.amenitySwitching)
	const modelRefs = useRef({
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
		8: [],
		9: [],
		25: []
	})
	const duration = 0.8

	useEffect(() => {
		if (amenityModelVisible) {
			setVisible(true)
		}
	}, [amenityModelVisible])

	useEffect(() => {
		const modelArr = modelRefs.current[activeLevel as number]
		if (!modelArr) {
			return
		}
		modelArr.forEach((mesh, meshIndex, arr) => {
			if (mesh && visible) {
				const { level, index } = amenitiesConfig[activeLevel as number][meshIndex]
				gsap.to(mesh.material.uniforms.uOpacity, {
					value: amenitySwitching ? 0 : amenityModelVisible ? index === currentAmenityIndex ? 1 : 1 : 0,
					duration,
					// delay: amenityModelVisible ? 450 : 0,
					onComplete: () => {
						if (!amenityModelVisible && meshIndex === arr.length - 1) {
							setVisible(false)
						}
					},
					ease: Sine.easeInOut
				})
			}
		})
	}, [visible, amenityModelVisible, currentAmenityIndex, activeLevel, amenitySwitching])



	return (
		<group position={[0, 0, 0]}>
			<Connections currentAmenityIndex={currentAmenityIndex} />
			{visible &&

				amenitiesConfig[activeLevel as number]?.map((data, index) => {
					return (
						<AmenityModel
							data={{
								position: [0, 0, 0],
								meshID: data.meshID
							}}
							opacity={0}
							key={`model${index}`}
							ref={mesh => {
								if (mesh) {
									modelRefs.current[data.level][index] = mesh
								}
							}}
						/>
					)
				})}
		</group>
	)
}

const Connections = ({ currentAmenityIndex }) => {

	const matRef = useRef<THREE.MeshMatcapMaterial>()
	const meshRef = useRef<THREE.Mesh>()

	const { nodes } = useGLTF('/models/amenities-full/connections.glb')

	useEffect(() => {
		gsap.to(matRef.current, {
			// onStart: () => {
			// 	meshRef.current.visible = currentAmenityIndex === null ? true : false
			// },
			delay: currentAmenityIndex === null ? 0.5 : 0,
			opacity: currentAmenityIndex === null ? 1 : 0,
			duration: 0.5,
			onComplete: () => {
				meshRef.current.visible = currentAmenityIndex === null ? true : false
			}

		})
	}, [currentAmenityIndex])

	return <mesh
	ref={meshRef}
		renderOrder={-10}
		geometry={nodes.mesh_0.geometry}
	>
		<meshMatcapMaterial
			ref={matRef}
			transparent={true}
			color="#EBAE15"

		/>

	</mesh>
}

export default AmenityModels
