import styled from "styled-components"
import { QRCodeSVG } from "qrcode.react"

import Form from "./components/Form"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import TextFrame from "@components/TextFrame/TextFrame"

function Share() {
	const favorites = useStore(s => s.favorites)
	const encodedFav = btoa(`${favorites?.join(",")}`)
	return (
		<ShareWrapper className="centerREL">
			<TextFrame
				bottomText="1515"
				rightText="SURF"
				style={{
					position: "absolute",
					right: "-10rem",
					bottom: "-6rem",
					pointerEvents: "none",
					color: globalStyles.colors.white,
					fontSize: "10rem",
					letterSpacing: "6rem"
				}}
			/>
			<QRWrapper className="centerREL">
				<Form />
				<QR
					value={`https://share.1515surf.app?${encodedFav}`}
					size={400}
					bgColor={`${globalStyles.colors.white}`}
					fgColor={globalStyles.colors.main}
					level="Q"
					includeMargin
					id="qr"
				/>
			</QRWrapper>
		</ShareWrapper>
	)
}

export default Share

const ShareWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background: #e3e3e6;
`

const QRWrapper = styled.div`
	width: 80%;
	height: 70%;
	border-radius: 3rem;
	aspect-ratio: 1/1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2%;
`

const QR = styled(QRCodeSVG)`
	width: fit-content;
	aspect-ratio: 1/1;
`
