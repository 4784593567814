import TSVGPropTypes from "@customTypes/SVGPropTypes"

function CubeIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
  return (
    <svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<polygon
				points="95 72.5 95 27.5 50 5 5 27.5 5 72.5 50 95 95 72.5"
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<polyline
				points="95 27.5 50 45.52 5 27.5 50 45.52 50 95"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
  )
}

export default CubeIcon