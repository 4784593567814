import { Fragment, useEffect, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import TextFrame from "@components/TextFrame/TextFrame"
import Popup from "@components/Popup"
import BurgerIcon from "@components/BurgerIcon"
import CloseIcon from "@components/SVGIcons/CloseIcon"

import { useStore } from "@state/store"
import routes from "@routes/routes"
import globalStyles from "@data/globalStyles"

function Navigation() {
	const [menuVisible, setMenuVisible] = useState(false)
	const sceneLoaded = useStore(s => s.sceneLoaded)
	const navOpen = useStore(s => s.navOpen)
	const amenityModelVisible = useStore(s => s.amenityModelVisible)
	const setAmenityModelVisible = useStore(s => s.setAmenityModelVisible)
	const setNavOpen = useStore(s => s.setNavOpen)
	const navigating = useStore(s => s.navigating)
	const setNavigating = useStore(s => s.setNavigating)
	const cardOpen = useStore(s => s.cardOpen)
	const amenityRenderOpen = useStore(s => s.amenityRenderOpen)
	const galleryPopup = useStore(s => s.galleryPopup)
	const sharePopup = useStore(s => s.sharePopup)
	const navigate = useNavigate()

	const hideCond =
		cardOpen || amenityRenderOpen || !sceneLoaded || galleryPopup || sharePopup

	function handleMenuClick() {
		if (!navOpen) {
			setNavOpen(true)
			setTimeout(() => {
				setMenuVisible(true)
			}, 300)
		} else {
			setMenuVisible(false)
			setTimeout(() => {
				setNavOpen(false)
			}, 600)
			setTimeout(() => {
				setNavigating(false)
			}, 1000)
		}
	}

	useEffect(() => {
		if (navigating && !navOpen) {
			setTimeout(() => {
				setNavigating(false)
			}, 600)
		}
	}, [navigating])

	function handleMenuBtnClick(e) {
		e.stopPropagation()
		setNavigating(true)
		navigate("/surf")
		if (navOpen) {
			setNavOpen(false)
			setMenuVisible(false)
		}
	}

	return (
		<Fragment>
			<MenuButton
				$active={navOpen || !sceneLoaded}
				onClick={e => {
					if (amenityModelVisible) {
						setAmenityModelVisible(false)
						setTimeout(() => {
							handleMenuBtnClick(e)
						}, 1500)
					} else {
						handleMenuBtnClick(e)
					}
				}}
				style={{ opacity: sceneLoaded ? 1 : 0 }}>
				<TextFrame
					topText="1515"
					leftText="SURF"
					style={{ width: "15rem", height: "20rem" }}
				/>
			</MenuButton>
			<BurgerIcon
				cue={navOpen}
				onClick={handleMenuClick}
				onClickDelay={0}
				width="clamp(20px, 2.5rem, 50px)"
				color={globalStyles.colors.white}
				isAnimated
				style={{
					transition: "opacity 0.5s ease-in-out",
					opacity: hideCond ? 0 : 1,
					pointerEvents: hideCond ? "none" : "auto"
				}}
			/>
			<Popup
				openState={navOpen}
				setOpenState={setNavOpen}
				durationMS={800}
				closeButton={<>{null}</>}>
				<NavWrapper>
					{routes.map((obj, i) => {
						return (
							obj.onNav && (
								<Fragment key={`navBtn${i}`}>
									<NavButton
										onClick={() => {
											setNavigating(true)
											navigate(obj.path)
											handleMenuClick()
										}}>
										<span
											key={i}
											style={{
												transform: menuVisible
													? "translateX(0%)"
													: "translateX(100%)",
												opacity: menuVisible ? 1 : 0,
												transitionDelay: `${100 * i}ms`
											}}>
											{obj.name}
										</span>
									</NavButton>
									<Border
										style={{
											clipPath: menuVisible
												? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
												: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
											transitionDelay: menuVisible
												? `${200 * i}ms`
												: `${100 * i}ms`
										}}
									/>
								</Fragment>
							)
						)
					})}
				</NavWrapper>
			</Popup>
		</Fragment>
	)
}

export default Navigation

type TNavBtn = {
	$active: boolean
}

const NavWrapper = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.black};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1rem;
`
// prettier-ignore
const MenuButton = styled.div <TNavBtn>`
	width: 10rem;
	height: 10rem;
	overflow: hidden;
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0;
	cursor: pointer;
	pointer-events: ${p => (p.$active ? "none" : "auto")};
	transition-property: opacity;
	transition-duration: 0.8s;
	transition-timing-function: ease-in-out;

	& p, path{
		transition-duration: 0.8s;
		transition-timing-function: ease-in-out;
	}
	
	& p{
		color: ${p =>
			p.$active ? globalStyles.colors.secondary : globalStyles.colors.white
		};
		transition-property: color;
	}

	& path{
		fill: ${p =>
			p.$active ? globalStyles.colors.secondary : globalStyles.colors.white
		};
		transition-property: fill;
	}
`

const NavButton = styled.div`
	position: relative;
	font-size: 3rem;
	margin-top: 2rem;
	color: ${globalStyles.colors.secondary};
	font-family: title;
	overflow: hidden;
	cursor: pointer;

	& span {
		display: inline-block;
		opacity: 0;
		transform: translateX(100%);
		transition-property: opacity, transform;
		transition-duration: 800ms;
		transition-timing-function: cubic-bezier(0.48, -0.04, 0.49, 1.08);
	}
`

const Border = styled.div`
	height: 0.1rem;
	width: 100%;
	background-color: ${globalStyles.colors.secondary};
	clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
	transition: clip-path 1.6s cubic-bezier(0.48, -0.04, 0.49, 1.08);
`
