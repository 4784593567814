import styled from "styled-components"

import SlideOne from "./components/SlideOne"

function AnimatedIntroText() {
	return (
		<SlidesWrapper>
			<svg width="60%" height="60%" viewBox="0 0 1000 1000" className="centerABS">
				<SlideOne />
			</svg>
		</SlidesWrapper>
	)
}

export default AnimatedIntroText

const SlidesWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	
	& image,
	path,
	polygon,
	#image,
	#coneyText,
	#background {
		transform-origin: center;
		transform-box: fill-box;
	}
`
