/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
const messages = {
  noChars: "can't contain special characters",
  noEmpty: "can't be empty",
  notLongEnough: "Phone must have at least 10 digits",
  // onlyNumbers: "Phone must only use numbers",
  validEmail: "Please provide a valid email",
  validCC: "Please provide a valid email to CC",
  noData: "Form must be filled"
}

export function validation(obj) {
  let message
  if (Object.values(obj).every(v => v === null || v === "")) {
    message = messages.noData
  } else {

    for(const key in obj){
      if(obj[key] === null || obj[key] === ""){
        message = `${key} ${messages.noEmpty}`
        break
      }else{
        if(key.toLowerCase() === "email"){
          if(!validateEmail(obj[key])){
            message = messages.validEmail
          }
        }else{
          if(!checkForChars(obj[key])){
            message = `${key} ${messages.noChars}`
            break
          }
        }
      }
    }
  }

  return message
}

function checkForChars(value) {
  return /[A-Za-z]$/i.test(value)
}

// function checkPhoneLength(value){
//     return value?.toString().length >= 10
// }

// function checkForNumbers(value){
//     return /[0-9]+$/i.test(value?.toString())
// }

function validateEmail(value) {
  return value
    ?.toString()
    .match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

function validateCC(value) {
  if (value) {
    return value
      .toString()
      .match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  } else {
    return true
  }
}
