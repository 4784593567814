import TSVGPropTypes from "@customTypes/SVGPropTypes"

function CloseIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			width="100%"
			height="100%"
			{...props}>
			<circle cx="50" cy="50" r="50" fill={bgColor ?? "none"} />
			<line
				x1="5"
				y1="5"
				x2="95"
				y2="95"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
			<line
				x1="95"
				y1="5"
				x2="5"
				y2="95"
				fill="none"
				stroke={strokeColor}
				strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default CloseIcon
