import { useState, useEffect } from "react"
import { DefaultLoadingManager } from "three"

const HandleLoadingProgress = ({ totalAssets, logassets, onLoaded }) => {
	const [progress, setProgress] = useState(0)

	const handleLoading = progress => {
		setProgress(progress)
	}

	DefaultLoadingManager.onError = function (url) {
		console.log("There was an error loading " + url)
	}

	DefaultLoadingManager.onProgress = function (url, itemsLoaded, totalAssets) {
		logassets && console.log("object", itemsLoaded, totalAssets)
		handleLoading((itemsLoaded / totalAssets) * 100)
	}

	useEffect(() => {
		if (progress === 100) {
			onLoaded && onLoaded()
		}
	}, [progress])

	return null
}

export default HandleLoadingProgress
