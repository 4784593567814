import { forwardRef, useMemo } from "react"
import styled from "styled-components"

import TextFrame from "@components/TextFrame/TextFrame"
import StarIcon from "@components/SVGIcons/StarIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import { toggleArrayItem } from "@utils/functions"

const FavCard = forwardRef<HTMLDivElement, { [x: string]: any }>(function Card(
	{ unit, ...props },
	ref
) {
	const fullUnits = useStore(s => s.fullUnits)
	const setCardOpen = useStore(s => s.setCardOpen)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const favorites = useStore(s => s.favorites)
	const setFavorites = useStore(s => s.setFavorites)
	const currentUnit = useMemo(() => {
		if (fullUnits) {
			return fullUnits.filter(obj => obj.unit === unit)[0] ?? null
		}
	}, [fullUnits])
	return (
		currentUnit && (
			<FavCardWrapper
				ref={ref}
				onClick={() => {
					setCurrentApt(currentUnit)
					setCardOpen(true)
				}}
				{...props}>
				<TextFrame
					topText={`APT ${currentUnit?.unit}`}
					leftText={`LEVEL ${currentUnit?.floor}`}
					className="frame"
					style={{
						width: "12rem",
						height: "12rem",
						top: "0rem",
						left: "0px",
						fontSize: "0.8rem"
					}}
				/>
				<img
					alt=""
					src={`/images/plans/unit-${currentUnit?.type.toLowerCase()}.svg`}
					className="centerABS"
				/>
				<Info>
					<p>{`${currentUnit?.beds} ${
						currentUnit?.beds > 1 ? "BEDS" : "BED"
					}`}</p>
					<p>{`${currentUnit?.baths} ${
						currentUnit?.baths > 1 ? "BATHS" : "BATH"
					}`}</p>
					<p>{`${currentUnit?.area} SQFT`}</p>
					<p>{`$ ${currentUnit?.price}`}</p>
				</Info>
				<Fav>
					<StarIcon
						strokeWidth={5}
						strokeColor={globalStyles.colors.main}
						bgColor={globalStyles.colors.main}
						// onClick={(e) => {
						// 	e.stopPropagation()
						// 	const toggled = toggleArrayItem(favorites, currentUnit?.unit)
						// 	setFavorites(toggled)
						// }}
					/>
				</Fav>
			</FavCardWrapper>
		)
	)
})

export default FavCard

const FavCardWrapper = styled.div`
	width: 35vw;
	height: 30vh;
	background-color: ${globalStyles.colors.white};

	.frame {
		position: relative;
		width: 50%;
		left: 0;
		height: 90%;
		touch-action: pan-y;
		pointer-events: auto;
		font-size: clamp(10px, 2rem, 60px);
	}

	& img {
		width: auto;
		height: 80%;
	}
`

const Info = styled.div`
	position: absolute;
	bottom: 2%;
	right: 2%;
	width: 20vw;
	height: fit-content;
	font-family: title;
	transition: opacity 0.6s ease-in;

	& p {
		width: 100%;
		text-align: right;
		font-size: 1.5rem;
		color: ${globalStyles.colors.black};
	}
`

const Fav = styled.div`
	position: absolute;
	width: 2rem;
	top: 2%;
	right: 2%;
	aspect-ratio: 1/1;
	display: grid;
	place-content: center;
	cursor: pointer;
`
