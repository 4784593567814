import globalStyles from "@data/globalStyles"
import styled from "styled-components"
import { toggleArrayItem } from "@utils/functions"
import { device } from "@utils/device"

const bedsArr = [
	{
		label: "one",
		key: 1,
		color: globalStyles.colors.accent
	},
	{
		label: "two",
		key: 2,
		color: globalStyles.colors.accent
	}
]

function BedsFilter({ beds, setBeds }) {
	return (
		<Group>
			{bedsArr.map((obj, i) => {
				return (
					<Button
						key={`bedsBtn${i}`}
						style={{
							color: beds.includes(obj.key)
								? globalStyles.colors.secondary
								: globalStyles.colors.gray
						}}
						onClick={() => {
							const toggled = toggleArrayItem(beds, obj.key)
							setBeds(toggled)
						}}>
						{obj.label}
					</Button>
				)
			})}
		</Group>
	)
}

export default BedsFilter

const Group = styled.div`
	width: 90%;
	display: flex;
	flex-direction: row;
	gap: 2rem;

	@media ${device.pad} {
		gap: 0rem;
	}
`

const Button = styled.div`
	width: 2.2rem;
	aspect-ratio: 1/1;
	display: flex;
	justify-content: center;
	font-family: title;
	cursor: pointer;
	pointer-events: all;
	transition: all 0.5s ease-out;
`
