import { useRef } from "react"
import { useThree } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"

type TProps =
  | {
      hasLimit?: undefined | false
    }
  | {
      hasLimit?: true
      maxHor: number
      minHor: number
      maxVer: number
      minVer: number
    }

function CameraControls(props : TProps) {
  const {
    camera,
    gl: { domElement },
  } = useThree()
  const controls = useRef()
  return (
    <>
      {props.hasLimit ? (
        <OrbitControls
          {...props}
          ref={controls}
          args={[camera, domElement]}
          enableZoom={false}
          autoRotate={false}
          rotateSpeed={-0.3}
          minAzimuthAngle={props.minHor}
          maxAzimuthAngle={props.maxHor}
          minPolarAngle={props.minVer}
          maxPolarAngle={props.maxVer}
        />
      ) : (
        <OrbitControls
          enableZoom={false}
          autoRotate={false}
          rotateSpeed={-0.3}
        />
      )}
    </>
  )
}

export default CameraControls
