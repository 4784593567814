import { useState, useEffect } from "react"
import styled from "styled-components"
import { useRanger } from "react-ranger"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function LevelRange() {
	const isIpad = useStore(s => s.isIpad)
  const setLocalState = useStore((s) => s.setLocalState)
  const levelRange = useStore((s) => s.levelRange)
  const minLevel = useStore((s) => s.minLevel)
  const maxLevel = useStore((s) => s.maxLevel)
  const [level, setLevel] = useState(levelRange)

	const { getTrackProps: levelTrack, handles: levelHandles } = useRanger({
		values: level,
		onDrag: setLevel,
    onChange: () => {
      setLocalState("levelRange", [level[0], level[1]])
    },
		min: minLevel,
		max: maxLevel,
		stepSize: 1
	})

	useEffect(() => {
    setLocalState("levelRange", [level[0], level[1]])
  }, [minLevel, maxLevel])

  useEffect(() => {
    setLevel(level)
  }, [levelRange])

	return (
		<Wrapper>
			<Labels
				style={{
					justifyContent: isIpad ? "space-between" : "center",
					fontSize: isIpad ? "1rem" : "1.3rem",
					padding: "2rem 0"
				}}>
				<Min>{level[0] < 10 ? `0${level[0]}` : level[0]}</Min>
				<Max>{level[1]}</Max>
			</Labels>
			<Range>
				<div
					{...levelTrack({
						style: {
							position: "absolute",
							top: "50%",
							left: "10%",
							height: "0.1rem",
							width: "80%",
							background: globalStyles.colors.secondary,
							borderRadius: "2px"
						}
					})}>
					{levelHandles.map(({ getHandleProps }) => (
						<div
							{...getHandleProps({
								style: {
									width: "1.5rem",
									height: "1.5rem",
									cursor: "pointer",
									borderRadius: "100%",
									position: "absolute",
									pointerEvents: "all"
								}
							})}
						/>
					))}
				</div>
			</Range>
		</Wrapper>
	)
}

export default LevelRange

const Wrapper = styled.div`
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const Range = styled.div`
	position: relative;
	flex: 1;
	width: 100%;
	height: 10%;
	font-size: 1rem;

	& div {
		:nth-of-type(1) {
			background-color: ${globalStyles.colors.secondary};
		}
		:nth-of-type(2) {
			background-color: ${globalStyles.colors.secondary};
		}
	}
`

const Labels = styled.div`
	flex: 1;
	width: 85%;
	height: 10%;
	display: flex;
	align-items: center;
	gap: 3rem;
`

const Min = styled.p``

const Max = styled.p``
