import { useEffect, useRef } from "react"
import { useGLTF, useTexture } from "@react-three/drei"
import * as THREE from "three"
import { gsap } from "gsap"

import { useStore } from "@state/store"

const paths = Object.keys(
	//@ts-ignore
	import.meta.glob("../../../..//public/models/floorplans/*.glb", { as: "url" })
).map(src => src.substring(18))

useGLTF.preload(paths)

function PlanMesh() {
	const cardSectionIndex = useStore(s => s.cardSectionIndex)
	const currentApt = useStore(s => s.currentApt)
	// const apt = useGLTF(`/models/floorplans/L.glb`)
	// const map = useTexture(`/textures/floorplans/L.jpg`)
	const apt = useGLTF(`/models/floorplans/${currentApt.type}.glb`)
	const map = useTexture(`/textures/floorplans/${currentApt.type}.webp`)
	map.flipY = false
	// map.encoding = THREE.sRGBEncoding

	const animated = useRef<THREE.Mesh>()
	const duration = 2

	function tween() {
		if (animated.current) {
			gsap.to(animated.current.material, {
				opacity: cardSectionIndex === 0 ? 1 : 0,
				duration: duration * 1.15
			})
			gsap.to(animated.current.position, {
				y: cardSectionIndex === 0 ? 0.1 : -1.5,
				duration
			})
			gsap.to(animated.current.rotation, {
				y: cardSectionIndex === 0 ? -0.15 : -1.6,
				duration
			})
		}
	}

	useEffect(() => {
		tween()
	}, [cardSectionIndex])
	return (
		<group scale={0.4}>
			<mesh
				//@ts-ignore
				geometry={apt.nodes.mesh_0.geometry}
				position={[0, -1.5, 0]}
				rotation={[0, -1.6, 0]}
				ref={animated}>
				{/* <planeGeometry/> */}
				<meshBasicMaterial
					opacity={0}
					map={map}
					toneMapped={false}
					side={THREE.FrontSide}
					transparent
				/>
			</mesh>
		</group>
	)
}

export default PlanMesh
