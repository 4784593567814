import CircleText from "@components/SVGIcons/CircleText"
import EyeIcon from "@components/SVGIcons/EyeIcon"
import globalStyles from "@data/globalStyles"
import styled from "styled-components"

function NavCircle({ text }) {
	return (
		<>
			<Wrapper className="centerABS">
				<TextWrapper>
					<CircleText text={text} className="centerABS text" animated />
				</TextWrapper>
				{/* <EyeIcon /> */}
			</Wrapper>
		</>
	)
}

export default NavCircle

const Wrapper = styled.div`
	cursor: pointer;
	position: absolute;
	/* width: 4.95438rem;
	height: 4.95438rem; */
	width: 5.2rem;
	height: 5.2rem;
	display: flex;
	align-items: center;
	background-color: ${globalStyles.colors.secondary};
	border-radius: 100%;
	text-align: center;
	justify-content: center;
	pointer-events: auto;
	border: 1px solid ${globalStyles.colors.accent};
	transform: scale(1.8);
	transform-origin: 120% 120%;
`

const TextWrapper = styled.div`
	position: absolute;

	width: 5.2rem;
	height: 5.2rem;
	max-width: 5.1vw;
	aspect-ratio: 1/1;
	transition: opacity 1.5s ease-in-out;
	transform: rotate(-123.257deg);

	.text {
		font-size: 10rem;
		font-family: title;
		fill: ${globalStyles.colors.accent};
		text-align: right;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`
