import { useEffect, useRef } from "react"
import * as THREE from "three"
import { useLoader } from "@react-three/fiber"

import HotSpot from "./HotSpot"
import gsap, { Sine } from "gsap"
import { useStore } from "@state/store"

type TProps = {
  texture: string
  hasLimit?: boolean
  callback?: () => void
  initialPosition: [number, number, number]
}
// function Sphere({ texture, hotSpots }) {
function Sphere({ texture, hasLimit, callback, initialPosition }: TProps) {
  const matRef = useRef<THREE.MeshBasicMaterial>()
  const cardSectionIndex = useStore(s => s.cardSectionIndex)
  const mainTexture = useLoader(THREE.TextureLoader, texture, () => { callback && callback() }) as any
  mainTexture.minFilter = THREE.LinearFilter
  mainTexture.wrapS = THREE.RepeatWrapping
  mainTexture.repeat.x = -1
  // if (hasLimit) {
  //   mainTexture.repeat.x = -2
  //   mainTexture.repeat.y = 7.5
  //   mainTexture.wrapT = THREE.RepeatWrapping
  //   mainTexture.offset.y = 0.93
  // }

  useEffect(() => {
    gsap.to(matRef.current, {
      duration: 0,
      opacity: 0,
      ease: Sine.easeInOut
    })
}, [])

  useEffect(() => {
		if (cardSectionIndex === 2) {
			gsap.to(matRef.current, {
				duration: 1,
        delay: 0.3,
				opacity: 1,
				ease: Sine.easeInOut
			})
		}
	}, [cardSectionIndex])



  return (
    <mesh visible position={[0, 0, 0]} rotation={initialPosition} >
      <sphereGeometry args={[800, 60, 40]} />
      <meshBasicMaterial
        toneMapped={false}
        map={mainTexture}
        side={THREE.BackSide}
        ref={matRef}
        transparent
      />
      {/* {hotSpots?.map((theta, i) => (
        <HotSpot key={i} theta={theta} />
      ))} */}
    </mesh>
  )
}

export default Sphere
