import globalStyles from "@data/globalStyles"
import styled from "styled-components"
import { toggleArrayItem } from "@utils/functions"
import { device } from "@utils/device"

const aspectsArray = [
	{ label: "Ocean View", key: "S W" },
	{ label: "City View", key: "N E" }
]

function AspectFilter({ aspects, setAspects }) {
	return (
		<Group>
			{aspectsArray.map((obj, i) => {
				return (
					<Button
						key={`aspectsBtn${i}`}
						style={{
							color: aspects.includes(obj.key)
								? globalStyles.colors.secondary
								: globalStyles.colors.gray
						}}
						onClick={() => {
							const toggled = toggleArrayItem(aspects, obj.key)
							setAspects(toggled)
						}}>
						{obj.label}
					</Button>
				)
			})}
		</Group>
	)
}

export default AspectFilter

const Group = styled.div`
	width: 90%;
	display: flex;
	flex-direction: row;
	gap: 2rem;

	@media ${device.pad} {
		gap: 0rem;
	}
`

const Button = styled.div`
	display: flex;
	justify-content: center;
	font-family: title;
	cursor: pointer;
	pointer-events: all;
	transition: all 0.5s ease-out;
`
