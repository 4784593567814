import React from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"

import { useStore } from "@state/store"

type Props = {}

const ControlsCatcher = (props: Props) => {
	const canvasReference = React.useRef<any>(null)
	const setcontrolsCatcher = useStore(s => s.setcontrolsCatcher)
	const isInt = useStore(s => s.isInt)
	const { pathname } = useLocation()

	const controlPaths = ["/residences", "/surf", "/amenities"]

	React.useEffect(() => {
		setcontrolsCatcher(canvasReference.current)
		return () => setcontrolsCatcher(null)
	}, [canvasReference])

	return (
		<EventElementWr
			ref={canvasReference}
			style={{
				display: controlPaths.includes(pathname) || isInt ? "initial" : "none"
			}}
		/>
	)
}

export default ControlsCatcher

const EventElementWr = styled.div`
	cursor: pointer;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`
