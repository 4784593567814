import { useState, useEffect } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { sortBy } from "lodash-es"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import StarIcon from "@components/SVGIcons/StarIcon"
import { device } from "@utils/device"

import { toggleArrayItem } from "@utils/functions"

function TableComponent() {
	const [orderState, setOrderState] = useState("APT")
	const [orderDir, setOrderDir] = useState(true)

	const filteredUnits = useStore(s => s.filteredUnits)
	const showList = useStore(s => s.showList)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const currentApt = useStore(s => s.currentApt)
	const setShowApartmentInfo = useStore(s => s.setShowApartmentInfo)

	const favourites = useStore(s => s.favorites)
	const setFavorites = useStore(s => s.setFavorites)

	const { id } = useParams()

	const isFave = apartment => favourites.some(e => e === apartment["unit"])

	const isActive = apartment => apartment === currentApt

	function trim(arr) {
		return arr.map((obj, i) => {
			return [
				obj["unit"],
				obj["price"],
				obj["beds"],
				obj["floor"],
				obj["area"],
				<StarIcon
					onClick={e => {
						e.stopPropagation()
						const toggled = toggleArrayItem(favourites, obj["unit"])
						setFavorites(toggled)
					}}
					width={10}
					strokeWidth={5}
					strokeColor={
						isFave(obj)
							? globalStyles.colors.secondary
							: globalStyles.colors.white
					}
					fill={
						isFave(obj)
							? globalStyles.colors.secondary
							: globalStyles.colors.white
					}
				/>,
				obj
			]
		})
	}

	const orderByApartment = sortBy(filteredUnits, [
		function (o) {
			return parseInt(o["unit"])
		},
		function (o) {
			return parseInt(o["beds"])
		},
		function (o) {
			return parseInt(o["floor"])
		}
	])

	const orderByPrice = sortBy(filteredUnits, [
		function (o) {
			return parseInt(o["price"])
		},
		function (o) {
			return parseInt(o["beds"])
		},
		function (o) {
			return parseInt(o["floor"])
		}
	])

	const orderByBeds = sortBy(filteredUnits, [
		function (o) {
			return parseInt(o["beds"])
		},
		function (o) {
			return parseInt(o["price"])
		},
		function (o) {
			return parseInt(o["floor"])
		}
	])

	const orderByFloor = sortBy(filteredUnits, [
		function (o) {
			return parseInt(o["floor"])
		},
		function (o) {
			return parseInt(o["price"])
		},
		function (o) {
			return parseInt(o["beds"])
		}
	])

	const orderByArea = sortBy(filteredUnits, [
		function (o) {
			return parseInt(o["area_ft"])
		},
		function (o) {
			return parseInt(o["price"])
		},
		function (o) {
			return parseInt(o["beds"])
		}
	])

	const orderTypes = {
		APT: orderDir ? trim(orderByApartment) : trim(orderByApartment).reverse(),
		PRICE: orderDir ? trim(orderByPrice) : trim(orderByPrice).reverse(),
		BEDS: orderDir ? trim(orderByBeds) : trim(orderByBeds).reverse(),
		FLOOR: orderDir ? trim(orderByFloor) : trim(orderByFloor).reverse(),
		AREA: orderDir ? trim(orderByArea) : trim(orderByArea).reverse()
	}

	const arrow = orderDir ? `\u2191` : `\u2193`

	const headers = [
		{
			label: (
				<p>
					APT <span>{arrow}</span>
				</p>
			),
			state: "APT"
		},
		{
			label: (
				<p>
					PRICE <span>{arrow}</span>
				</p>
			),
			state: "PRICE"
		},
		{
			label: (
				<p>
					BEDS <span>{arrow}</span>
				</p>
			),
			state: "BEDS"
		},
		{
			label: (
				<p>
					FLOOR <span>{arrow}</span>
				</p>
			),
			state: "FLOOR"
		},
		{
			label: (
				<p>
					SQ FT <span>{arrow}</span>
				</p>
			),
			state: "AREA"
		},
		{
			label: (
				<StarIcon
					width={10}
					strokeColor={globalStyles.colors.white}
					color={globalStyles.colors.white}
					fill={globalStyles.colors.white}
					strokeWidth={5}
				/>
			),
			state: null
		}
	]

	useEffect(() => {
		if (id) {
			const unit = filteredUnits.find(obj => obj.unit === Number(id))
			if (unit) {
				setCurrentApt(unit)
			}
		}
	}, [id])

	useEffect(() => {
		const row = document.getElementsByClassName("active")[0]
		if (row) {
			row.scrollIntoView({
				behavior: "smooth",
				block: "center"
			})
		}
	}, [currentApt, showList])

	return (
		<>
			{showList ? (
				<TableWrapper>
					<TableContainer>
						<Table>
							<TableHeader>
								<tr>
									{headers.map((obj, i) => {
										return (
											<th
												key={`header${i}`}
												className={orderState === obj.state ? "filteredBy" : ""}
												onClick={() => {
													if (orderState === obj.state) {
														obj.state && setOrderDir(!orderDir)
													} else {
														obj.state && setOrderState(obj.state)
													}
												}}>
												{obj.label}
											</th>
										)
									})}
								</tr>
							</TableHeader>
							<TableBody>
								{orderTypes[orderState].map((apt, i) => {
									return (
										<tr
											key={i}
											onClick={() => {
												setCurrentApt(apt[apt.length - 1])
												setShowApartmentInfo(true)
											}}
											className={isActive(apt[apt.length - 1]) ? "active" : ""}>
											{headers.map((txt, i) => {
												return <td key={`column${i}`}>{apt[i]}</td>
											})}
										</tr>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</TableWrapper>
			) : null}
		</>
	)
}

export default TableComponent

const TableWrapper = styled.div`
	position: absolute;
	z-index: 3;
	max-height: 70%;
	top: 50%;
	transform: translateY(-50%);
	width: 50rem;
	margin-left: 1.75rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	animation: fadeIn 0.6s ease-in-out;
`

const TableContainer = styled.div`
	/* border: red solid 3px; */
	overflow-y: scroll;
	touch-action: pan-y;
	/* height: 38rem; */
	height: 100%;
	font-size: 1.37888rem;
	font-family: title;
	color: ${globalStyles.colors.secondary};
	background-color: ${globalStyles.colors.black}2c;

	& * {
		touch-action: pan-y;
	}

	::-webkit-scrollbar {
		display: none;
	}

	@media ${device.pad} {
		height: 30rem;
	}

	@media ${device.tablet} {
		height: 40rem;
		max-width: calc(75%);
	}
`

const Table = styled.table`
	position: relative;
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-radius: 2.5rem;
	transition: opacity 0.5s ease-out;
	.active {
		background-color: ${globalStyles.colors.secondary}44;
	}
	.filteredBy {
		& span {
			opacity: 1;
		}
	}
`

const TableHeader = styled.thead`
	color: ${globalStyles.colors.white};
	font-family: title;
	font-size: 1rem;
	letter-spacing: 0.1rem;
	top: 0;
	position: sticky;
	z-index: 2;
	background-color: ${globalStyles.colors.accent};

	& th {
		height: 4rem;
		font-weight: 100;
		cursor: pointer;
		pointer-events: all;
		position: relative;

		& span {
			opacity: 0;
			transition: opacity 0.5s ease-in;
		}

		& div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	@media ${device.tablet} {
		font-size: 1.2rem;
	}
`

const TableBody = styled.tbody`
	text-align: center;
	font-family: title;
	font-weight: 100;
	position: relative;

	tr {
		border-bottom: 0.1rem solid ${globalStyles.colors.secondary};
		color: ${globalStyles.colors.white};
		height: 2.8rem;
		font-size: 0.8rem;
		letter-spacing: 0.1rem;
		transition: background-color 0.5s ease-in-out;

		& td {
			position: relative;
			pointer-events: all;
			cursor: pointer;
			height: 2.8rem;

			& div {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	@media ${device.tablet} {
		tr {
			font-size: 1.4rem;
		}
	}
`
