const amenitiesConfig = {
	1: [
		{
			level: 1,
			index: 0,
			meshID: "lobby",
			name: "The Lobby",
			position: [-0.317452, -0.428752, -0.155046],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: 0.5,
				target: [
					-0.11257422405730477, 0.008159284212602692, 0.023775424452846625
				],
				camera: [
					-0.0030306161178064034, 0.08091643281250012, 0.06783672135002797
				],
				fov: 55
			}
		},
		{
			level: 1,
			index: 20,
			meshID: "level-1-rest",
			name: null,
			position: [0.008492, 0.181461, 0.278893],
			copy: null,
			src: null,
			cameraConfig: null
		}
	],
	2: [
		{
			level: 2,
			index: 1,
			meshID: "coworking-lounge",
			name: "Co Working Lounge",
			position: [-0.161915, -0.394884, -0.154591],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .4,
				target: [
					0.01497286342359772, 0.007696632222259821, -0.07508065285855833
				],
				camera: [
					0.04254019095107158, 0.03951703316955781, 0.023063910223505416
				],
				fov: 55
			}
		},
		{
			level: 2,
			index: 2,
			meshID: "gallery-lounge",
			name: "Gallery Lounge",
			position: [-0.282301, -0.394884, -0.154591],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .5,
				target: [
					0.08740780329341058, 0.009820430453971463, 0.04638749593131796
				],
				camera: [
					0.04079755120544677, 0.07334184110205831, -0.07558818192907554
				],
				fov: 55
			}
		},
		{
			level: 2,
			index: 21,
			meshID: "level-2-rest",
			name: null,
			position: [0.008492, 0.181461, 0.278893],
			copy: null,
			src: null,
			cameraConfig: null
		}
	],
	3: [
		{
			level: 3,
			index: 3,
			meshID: "game-room",
			name: "Game Room",
			position: [-0.39316, -0.333019, -0.112906],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [
					-0.24716246734411512, 0.01962176217443718, 0.12438117406686691
				],
				camera: [
					-0.15973917339264115, 0.08300878412893495, 0.09075381297801044
				],
				fov: 55
			}
		},
		{
			level: 3,
			index: 4,
			meshID: "living-room",
			name: "Living Room",
			position: [-0.343246, -0.333019, -0.112906],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [-0.137527948493016, 0.012366604028195831, 0.10722302178840562],
				camera: [
					-0.06934235388855775, 0.08719146400641958, 0.05688278317359585
				],
				fov: 55
			}
		},
		{
			level: 3,
			index: 5,
			meshID: "beach-deck",
			name: "Garden",
			position: [0.001927, -0.337801, 0.111715],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: "/images/gallery/terrace.webp",
			cameraConfig: {
				zoomFactor: .7,
				target: [0, 0, 0],
				camera: [0.4735470021990888, 0.2872864541471451, -0.3651662616173255],
				fov: 55
			}
		},
		{
			level: 3,
			index: 22,
			meshID: "level-3-rest",
			name: null,
			position: [0.008492, 0.181461, 0.278893],
			copy: null,
			src: null,
			cameraConfig: null
		}
	],
	4: [
		{
			level: 4,
			index: 6,
			meshID: "media-lounge",
			name: "Media Lounge",
			position: [-0.233392, -0.298802, -0.049078],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [0, 0, 0],
				camera: [
					-0.07650110678107769, 0.06970275845227941, -0.06476288680940741
				],
				fov: 55
			}
		}
	],
	5: [
		{
			level: 5,
			index: 7,
			meshID: "the-library",
			name: "The Library",
			position: [-0.233392, -0.268871, -0.048417],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [0, 0, 0],
				camera: [
					0.09079290724932325, 0.10660918132582282, -0.07270083265990138
				],
				fov: 55
			}
		}
	],
	6: [
		{
			level: 6,
			index: 8,
			meshID: "the-court",
			name: "The Court",
			position: [-0.233392, -0.238871, -0.048417],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [0, 0, 0],
				camera: [
					0.09079290724932325, 0.10660918132582282, -0.07270083265990138
				],
				fov: 55
			}
		}
	],
	7: [
		{
			level: 7,
			index: 9,
			meshID: "the-gym",
			name: "The Gym",
			position: [-0.206018, -0.210036, -0.059228],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: "/images/gallery/gym.webp",
			cameraConfig: {
				zoomFactor: .45,
				target: [0, 0, 0],
				camera: [-0.23029359663597251, 0.1149014402425383, 0.19605222034425993],
				fov: 55
			}
		},
		{
			level: 7,
			index: 10,
			meshID: "the-studio",
			name: "The Studio",
			position: [-0.129292, -0.210036, -0.059228],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .45,
				target: [
					0.013338041907690841, 0.00703412551238427, 0.09829029670320465
				],
				camera: [
					-0.041539480725559866, 0.08267975400203209, 0.2769406437371085
				],
				fov: 55
			}
		}
	],
	8: [
		{
			level: 8,
			index: 11,
			meshID: "ocean-deck",
			name: "Ocean Deck",
			position: [-0.463443, -0.186045, 0.106847],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .45,
				target: [
					-0.0032428676816640225, 0.002902316206824255, 0.0067771275737183514
				],
				camera: [
					0.14028122639345642, 0.17613656386948232, -0.23231668711707923
				],
				fov: 55
			}
		},
		{
			level: 8,
			index: 12,
			meshID: "ocean-lounge",
			name: "Ocean Lounge",
			position: [-0.350494, -0.186045, 0.207692],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .45,
				target: [
					0.006148130881820481, 0.007034125512384216, 0.09900043572931976
				],
				camera: [
					-0.07502723914821532, 0.056896490054019, -0.0011588344323961164
				],
				fov: 55
			}
		},
		{
			level: 8,
			index: 13,
			meshID: "the-pool",
			name: "The Pool",
			position: [-0.350494, -0.186045, 0.04331],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: "/images/gallery/pool.webp",
			cameraConfig: {
				zoomFactor: .45,
				target: [
					0.03623189649361699, 0.006030883361180366, -0.07693981304872274
				],
				camera: [-0.05083624266340541, 0.0637619544109073, 0.08247402778603838],
				fov: 55
			}
		},
		{
			level: 8,
			index: 23,
			meshID: "level-8-rest",
			name: null,
			position: [0.008492, 0.181461, 0.278893],
			copy: null,
			src: null,
			cameraConfig: null
		}
	],
	9: [
		{
			level: 9,
			index: 14,
			meshID: "the-sun-deck",
			name: "The Sun Deck",
			position: [-0.374558, -0.160037, 0.004621],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [0, 0, 0],
				camera: [0.1950113213809478, 0.058634710449519734, -0.0978745316584488],
				fov: 55
			}
		}
	],
	25: [
		{
			level: 25,
			index: 15,
			meshID: "sky-deck",
			name: "The Sky Deck",
			position: [-0.364726, 0.335625, -0.036051],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [
					-0.05140352557048003, 0.008419475808172115, -0.040483614019953346
				],
				camera: [
					-0.1526801698303537, 0.10406283331687458, -0.16891375234603725
				],
				fov: 55
			}
		},
		{
			level: 25,
			index: 16,
			meshID: "sky-lounge",
			name: "The Sky Lounge",
			position: [-0.377302, 0.335625, -0.0891],
			copy: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			src: null,
			cameraConfig: {
				zoomFactor: .3,
				target: [0, 0, 0],
				camera: [
					-0.09877177436162504, 0.1491615141558208, -0.15670547735944687
				],
				fov: 55
			}
		}
	]
}

export default amenitiesConfig
