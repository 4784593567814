import styled from "styled-components"

import FloorplanManager from "@components/FloorplanManager"

import globalStyles from "@data/globalStyles"
import { Label } from "@components/Plan3DViewer/Plan3DViewer"

function FloorplanViewer({isInt}) {
	return (
		<ViewerWrapper>
			<Label isint={isInt}>
				<h1>FLOOR</h1>
				<h1>PLAN</h1>
			</Label>
			{/* <img alt="" src="/images/plans/floorplan.png" className="centerABS" /> */}
			<FloorplanManager />
		</ViewerWrapper>
	)
}

export default FloorplanViewer

const ViewerWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`
