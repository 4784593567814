import TSVGPropTypes from "@customTypes/SVGPropTypes"

function YardiIcon({
	strokeWidth,
	strokeColor,
	bgColor,
	...props
}: TSVGPropTypes) {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 46 47"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
				d="M23.7071 1.29289C23.3166 0.902369 22.6834 0.902369 22.2929 1.29289L15.9289 7.65685C15.5384 8.04738 15.5384 8.68054 15.9289 9.07107C16.3195 9.46159 16.9526 9.46159 17.3431 9.07107L23 3.41421L28.6569 9.07107C29.0474 9.46159 29.6805 9.46159 30.0711 9.07107C30.4616 8.68054 30.4616 8.04738 30.0711 7.65685L23.7071 1.29289ZM22 29C22 29.5523 22.4477 30 23 30C23.5523 30 24 29.5523 24 29H22ZM22 2V29H24V2H22Z"
			/>
			<path
				fill={bgColor}
				stroke={strokeColor}
				strokeWidth={strokeWidth}
				d="M8 16V15H7V16H8ZM8 42.5H7V43.5H8V42.5ZM38 42.5V43.5H39V42.5H38ZM38 16H39V15H38V16ZM30.5 15C29.9477 15 29.5 15.4477 29.5 16C29.5 16.5523 29.9477 17 30.5 17V15ZM15 15H8V17H15V15ZM7 16V42.5H9V16H7ZM8 43.5H38V41.5H8V43.5ZM39 42.5V16H37V42.5H39ZM38 15H30.5V17H38V15Z"
			/>
		</svg>
	)
}

export default YardiIcon
