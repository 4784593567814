import CloseIcon from "@components/SVGIcons/CloseIcon"
import RightArrowIcon from "@components/SVGIcons/RightArrowIcon"
import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import { device } from "@utils/device"
import styled from "styled-components"

const ApartmentInfo = () => {
	const currentApt = useStore(s => s.currentApt)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const setCardOpen = useStore(s => s.setCardOpen)
	const setShowApartmentInfo = useStore(s => s.setShowApartmentInfo)
	const showApartmentInfo = useStore(s => s.showApartmentInfo)
	const filteredUnits = useStore(s => s.filteredUnits)

	const handleClickNext = () => {
		const currentIndex = filteredUnits.indexOf(currentApt)
		const nextIndex =
			currentIndex === filteredUnits.length - 1 ? 0 : currentIndex + 1
		setCurrentApt(filteredUnits[nextIndex])
	}

	const handleClickPrev = () => {
		const currentIndex = filteredUnits.indexOf(currentApt)
		const prevIndex =
			currentIndex === 0 ? filteredUnits.length - 1 : currentIndex - 1
		setCurrentApt(filteredUnits[prevIndex])
	}

	return (
		<>
			{showApartmentInfo && currentApt && (
				<ApartmentInfoWrapper>
					<Info>
						<p>{currentApt.unit}</p>
						<p>Level {currentApt.floor}</p>
						<p>
							{currentApt.beds} bed + {currentApt.baths} bath
						</p>
						<p>{currentApt.area} sqft</p>
						<p>${currentApt.price}</p>
						<ViewButton onClick={() => setCardOpen(true)}>
							<p>View</p>
							<RightArrowIcon fill={globalStyles.colors.black} />
						</ViewButton>
						<NextPrevArrows>
							<Arrow onClick={handleClickPrev}>
								<h1>&larr;</h1>
							</Arrow>
							<Arrow onClick={handleClickNext}>
								<h1>&rarr;</h1>
							</Arrow>
						</NextPrevArrows>
					</Info>
					<CloseButton
						onClick={() => {
							setShowApartmentInfo(false)
							setCurrentApt(null)
						}}>
						<CloseIcon
							strokeWidth={5}
							strokeColor={globalStyles.colors.black}
							width="25"
						/>
					</CloseButton>
				</ApartmentInfoWrapper>
			)}
		</>
	)
}

export default ApartmentInfo

const ApartmentInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	justify-content: flex-end;
	position: absolute;
	transition: fadeIn 0.6s ease-in-out;
	padding: 11rem 2rem;
	margin-top: 100px;

	@media ${device.pad} {
		padding: 1rem;
	}
`
const Info = styled.div`
	display: flex;
	flex-direction: column;

	p {
		color: #c8c8cd;
		font-family: title;
		font-size: 1.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: 121%; /* 2.26875rem */
		letter-spacing: 0.46875rem;
		text-transform: uppercase;
	}
`
const ViewButton = styled.div`
	pointer-events: auto;
	cursor: pointer;

	p {
		display: inline;
		color: #000;
		font-family: title;
		font-size: 2.475rem;
		font-style: normal;
		font-weight: 400;
		line-height: 121%; /* 2.26875rem */
		letter-spacing: 0.46875rem;
		text-transform: uppercase;
	}
	:hover {
		p,
		svg path {
			color: ${globalStyles.colors.secondary};
			fill: ${globalStyles.colors.secondary};
			transition: 0.3s ease-in-out;
		}
	}
`
const NextPrevArrows = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const Arrow = styled.div`
	pointer-events: auto;
	cursor: pointer;
	color: ${globalStyles.colors.white};
	:hover {
		color: ${globalStyles.colors.secondary};
		transition: 0.3s ease-in-out;
	}
`

const CloseButton = styled.div`
	pointer-events: auto;
	cursor: pointer;

	:hover {
		svg line {
			stroke: ${globalStyles.colors.secondary};
			transition: 0.3s ease-in-out;
		}
	}
`
